import React from "react";
import { Switch, Route } from "react-router-dom";
import { EditItemForm, EditSetForm } from "./components";
import { AddActComp } from "./components/AddActivities/addActivities.component";
import { ActivityImageChooser } from "./components/AddActivities/changeActivityImage.component";
import { AddCatComp } from "./components/AddCategories/addCategories.component";
import { CatImageChooser } from "./components/AddCategories/categoryImages.component";
import { Discover } from "./components/Discover/";
import { ImageChooser, SetImageChooser } from "./components/Images";
import { ItemComponent } from "./components/Item/item.component";
import { LocationComp } from "./components/Location";
import Moderation from "./components/Moderation/moderation.component";
import { SetComp } from "./components/Set/set.component";
import { CreateSnafleSet, CreateSnafleItem } from "./screens";
import home from "./screens/home";
import MissionControl from "./screens/MissionControl";
import UserFeedBack from "./components/UserFeedback/userFeedback";
import ContentCreator from "./components/ContentCreators/contentCreators";
import ViewReport from "./components/ViewReport/ViewReport";
import Stats from "./screens/stats";
import ViewUserFeedback from "./components/viewUserFeedback/viewUserFeedback";

const Navigator = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/home" component={home} />
        <Route exact path="/stats" component={Stats} />
        <Route exact path="/createSnafleSet" component={CreateSnafleSet} />
        <Route path="/createSnafleSet/setLocation" component={LocationComp} />
        <Route path="/createSnafleSet/setImage" component={ImageChooser} />
        <Route
          exact
          path="/set/:id/createSnafleItem/:title"
          component={CreateSnafleItem}
        />

        <Route path="/editSetImage/:id" component={SetImageChooser} />
        <Route path="/createSnafleItem/setLocation" component={LocationComp} />
        <Route path="/createSnafleItem/setImage" component={ImageChooser} />
        <Route exact path="/set/:id" component={SetComp} />
        <Route exact path="/item/:id" component={ItemComponent} />
        <Route exact path="/editSet/:id" component={EditSetForm} />
        <Route exact path="/editItem/:id" component={EditItemForm} />
        <Route exact path="/editSnafleItem/setImage" component={ImageChooser} />

        <Route exact path="/addCategories" component={AddCatComp} />
        <Route exact path="/addActivities" component={AddActComp} />
        <Route exact path="/discoverData" component={Discover} />

        <Route
          exact
          path="/addCategories/addImage"
          component={CatImageChooser}
        />

        <Route
          exact
          path="/addActivities/addImage"
          component={ActivityImageChooser}
        />

        <Route exact path="/" component={MissionControl} />

        <Route exact path="/Moderation" component={Moderation} />
        <Route exact path="/UserFeedback" component={UserFeedBack} />
        <Route exact path="/ContentCreator" component={ContentCreator} />

        <Route exact path={`/Moderation/:reportId`} component={ViewReport} />
        <Route exact path={`/UserFeedback/:reportId`} component={ViewReport} />
        <Route
          exact
          path={`/ContentCreator/:reportId`}
          component={ViewReport}
        />

        <Route
          exact
          path={"/viewFeedback/:reportId"}
          component={ViewUserFeedback}
        ></Route>
      </Switch>
    </div>
  );
};

export default Navigator;
