import authorization from "../../authorization";
import { ImprovedLocation } from "../location";

interface currentSetActions {
  LOAD_CURRENT_SET: string;
  DELETE_DATA: string;
}

interface currentSet {
  title?: string;
  description?: string;
  location?: ImprovedLocation;
  activityType?: string;
  image?: { author: string; url: string; downloadLink: string };
  base64?: string;
  categoryId?: string;
  timedSet?: boolean;
  startDate?: Date;
  endDate?: Date;
  startDateTimeHours?: number;
  startDateTimeMinutes?: number;
  endDateTimeHours?: number;
  endDateTimeMinutes?: number;
}

const currentSetActionType: currentSetActions = {
  LOAD_CURRENT_SET: "LOAD_CURRENT_SET",
  DELETE_DATA: "DELETE_DATA",
};

export const loadCurrentSet = (data: currentSet) => ({
  type: currentSetActionType.LOAD_CURRENT_SET,
  payload: data,
});

export const deleteCurrentSet = () => ({
  type: currentSetActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

export const fetchCurrentSetData = (url: string) => {
  return async (dispatch: any) => {
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((response) => response.json())
      .then((jsonData) => {
        delete jsonData.setItems;
        dispatch(loadCurrentSet(jsonData! as currentSet));
      });
  };
};

const currentSetReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case currentSetActionType.LOAD_CURRENT_SET:
      return { ...state, data: action.payload };
    case currentSetActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default currentSetReducer;
