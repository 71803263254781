import styled from "styled-components";

export const NavbarContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 8vh;
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  background-color: #fff;
`;

export const ButtonInvisible = styled.button`
  border: none;
  background-color: white;
  outline: none;
  font-size: 1.2em;

  @media (min-width: 750px) {
    display: none;
  }
`;
