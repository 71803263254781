import React from "react";
import styled from "styled-components";
import { Button, Label } from "semantic-ui-react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router";
import { FiChevronLeft } from "react-icons/fi";
import { useAppDispatch } from "../../hooks";
import { deleteLocation } from "../../redux/location";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import { deleteImage } from "../../redux/image";
import { deleteBase64 } from "../../redux/base64";

const NavButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-family: "OpenSans-reg";
`;

const NavBarTopCstm = styled.div``;

export const NavBarTopCustom = (props: {
  buttonLeft?: string;
  buttonRight?: string;
  title: string;
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <div className="nav-bar-top-custom ">
      <FiChevronLeft
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
        size={20}
      />
      {props.title}
      <div
        onClick={() => {}}
        style={{
          color: "#00ABD9",
          border: "none",
          backgroundColor: "transparent",
          outline: "none",
          fontSize: "16px",
          padding: 0,
          margin: 0,
          fontFamily: "OpenSans-reg",
        }}
        className="navbar"
      >
        {props?.buttonRight || ""}
      </div>
    </div>
  );
};
