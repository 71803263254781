import React, { useEffect, useState } from "react";
import { BiMessageDetail } from "react-icons/bi";
import { BsCollectionFill } from "react-icons/bs";
import { FaUsers, FaArrowUp } from "react-icons/fa";

import { Icon, Tab, Table } from "semantic-ui-react";
import { NavBar } from "../components";
import "./stats.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import authorization from "../authorization";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = (title: string) => ({
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: title,
    },
  },
});

interface PopData {
  title: string;
  count: number;
}

interface ProAccounts {
  username: string;
  deviceType: "IOS" | "ANDROID";
  status: "ACTIVE";
  planId: string;
  expiryTimestamp: number;
}

interface UsersAndInterests {
  email: string;
  username: string;
  snafles: number;
  bio: string;
  dob: number;
  location: { country: string; name: string };
  name: string;
  verfied: boolean;
  interests: string[];
  createdDate: number;
}

const Stats = () => {
  const [users, setUsers] = useState(0);
  const [posts, setPosts] = useState(0);
  const [sets, setSets] = useState(0);
  const [dailyLabels, setDailyLabels] = useState<string[]>([]);
  const [dailyValues, setDailyValues] = useState<number[]>([]);
  const [genderDemo, setGenderDemo] = useState<string[]>([]);
  const [genderDemoValues, setGenderDemoValues] = useState<number[]>([]);
  const [platformDemo, setPlatformDemo] = useState<string[]>([]);
  const [platformDemoValues, setPlaftormDemoValues] = useState<number[]>([]);
  const [weeklyLabels, setWeeklyLabels] = useState<string[]>([]);
  const [weeklyValues, setWeeklyValue] = useState<number[]>([]);
  const [popSets, setPopSets] = useState<PopData[]>([]);
  const [popInts, setPopInts] = useState<PopData[]>([]);
  const [proUsers, setProUsers] = useState<ProAccounts[]>([]);
  const [userInterests, setUserInterests] = useState<any[]>([]);
  const [proCount, setProCount] = useState<number>(0);
  const [profileComplete, setProfileComplete] = useState<any[]>([]);

  const getData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getDatabaseData`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setUsers(data.userNum);
        setDailyLabels(Object.keys(data.dailyUsers));
        setDailyValues(Object.values(data.dailyUsers));
        setGenderDemo(Object.keys(data.genderDemo));
        setGenderDemoValues(Object.values(data.genderDemo));
        setPosts(data.postNum);
        setSets(data.setNum);
        setPlatformDemo(Object.keys(data.platforms));
        setPlaftormDemoValues(Object.values(data.platforms));
        setWeeklyLabels(Object.keys(data.weeklyUsers));
        setWeeklyValue(Object.values(data.weeklyUsers));
      })
      .catch((err) => console.log(err));
  };

  const getPopularSets = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getPopularSets`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        const sorted = Object.fromEntries(
          //@ts-ignore
          Object.entries(data.result).sort(([, a], [, b]) => b - a)
        );
        let formData = [];
        const keys = Object.keys(sorted);
        for (let i in keys) {
          formData.push({ title: keys[i], count: sorted[keys[i]] });
        }
        //@ts-ignore
        setPopSets(formData);
      })
      .catch((err) => console.log({ err }));
  };

  const getUsersAndInterests = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getUsersAndInterests`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        setUserInterests(data?.result);
      })
      .catch((err) => console.log({ err }));
  };

  const getPopularInterests = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getPopularInterests`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        const sorted = Object.fromEntries(
          //@ts-ignore
          Object.entries(data.result).sort(([, a], [, b]) => b - a)
        );
        let formData = [];
        const keys = Object.keys(sorted);
        for (let i in keys) {
          formData.push({ title: keys[i], count: sorted[keys[i]] });
        }
        //@ts-ignore
        setPopInts(formData);
      })
      .catch((err) => console.log({ err }));
  };

  const getProUsers = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getProAccounts`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setProUsers(data?.formattedData);
        setProCount(data?.count);
      })
      .catch((err) => console.log({ err }));
  };

  const getProfileCompleteData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getUserProfileComplete`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setProfileComplete(data?.formatted);
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    getData();
    getPopularSets();
    getUsersAndInterests();
    getPopularInterests();
    getProUsers();
    getProfileCompleteData();
  }, []);

  const usersToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dailyLabels.includes(today.toISOString())
      ? dailyValues[dailyValues.length - 1]
      : 0;
  };

  const getWeekBegining = (date: number): Date => {
    let d = new Date(date);
    let day = d.getDay();
    let diff = d.getDate() - day + (day == 0 ? -6 : 1);
    let begining = new Date(d.setDate(diff));
    begining.setHours(0, 0, 0, 0);
    return begining;
  };

  const usersThisWeek = () => {
    return weeklyValues[weeklyValues.length - 1] || 0;
  };

  const dailyAverage = () => {
    let total = dailyValues.length;
    let acc = 0;
    for (let i in dailyValues) {
      acc += dailyValues[i];
    }
    return acc / total;
  };

  const weeklyAverage = () => {
    let total = weeklyValues.length;
    let acc = 0;
    for (let i in weeklyValues) {
      acc += weeklyValues[i];
    }
    return acc / total;
  };

  const findHighestDate = () => {
    let max = 0;
    let index = 0;
    for (let i = 0; i < dailyValues?.length; i++) {
      if (max < dailyValues[i]) {
        max = dailyValues[i];
        index = i;
      }
    }
    return `${new Date(dailyLabels[index]).toLocaleDateString(
      "en-GB"
    )}, (${max} Users)`;
  };

  const highestWeek = () => {
    let max = 0;
    let index = 0;
    for (let i = 0; i < weeklyValues?.length; i++) {
      if (max < weeklyValues[i]) {
        max = weeklyValues[i];
        index = i;
      }
    }
    return `${new Date(weeklyLabels[index]).toLocaleDateString(
      "en-GB"
    )}, (${max} Users)`;
  };

  const DataContainer = ({ data }: any): React.ReactElement => {
    return (
      <div className={"navigationItem"} style={{ backgroundColor: data.color }}>
        <div className={"first-row"}>
          <data.Icon size={50} style={{ padding: 0, margin: 0 }} />
          <h3 className={"mission-title-text"}>{data.title}</h3>
          <h1 className={"data-text"}>{data.primaryData}</h1>
          {data.increase ? (
            <div style={{ textAlign: "center" }}>
              <FaArrowUp
                color={"green"}
                size={18}
                style={{ padding: 0, margin: 0 }}
              />
              <p style={{ fontSize: 18, textAlign: "center" }}>
                {data?.increase}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const Users = () => {
    return (
      <Tab.Pane>
        <div>
          <h2>Daily</h2>
          <Line
            options={{
              ...options("New Users (Daily)"),
              ...{
                scales: {
                  xAxis: {
                    display: true,
                    title: {
                      display: true,
                      text: "Dates",
                    },
                  },
                  yAxis: {
                    display: true,
                    title: {
                      display: true,
                      text: "Number of Users",
                    },
                  },
                },
              },
            }}
            data={{
              labels: dailyLabels.map((x: string) =>
                new Date(x).toLocaleDateString("en-GB")
              ),
              datasets: [
                {
                  label: "Daily New Users",
                  data: dailyValues,
                  yAxisID: "yAxis",
                  xAxisID: "xAxis",
                  borderColor: "rgb(244, 99, 5)",
                  backgroundColor: "rgba(244, 99, 5, 0.1)",
                  fill: true,
                },
              ],
            }}
          />
          <div>
            <div className="more-stats-container">
              <div className="more-stats-title">
                <div className="more-stats-column">
                  <div className="more-stats-row">
                    <h4>
                      New Accounts Today (
                      {new Date().toLocaleDateString("en-GB")})
                    </h4>
                    <h4>{usersToday()}</h4>
                  </div>
                  <div className="more-stats-row">
                    <h4>Daily Average</h4> <h4>{Math.round(dailyAverage())}</h4>
                  </div>
                  <div className="more-stats-row">
                    <h4>Highest User Number Of User Onboards</h4>
                    <h4>{findHighestDate()}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2>Weekly</h2>
            <Line
              options={{
                ...options("New Users (Weekly)"),
                ...{
                  scales: {
                    xAxis: {
                      display: true,
                      title: {
                        display: true,
                        text: "Week Begining Dates",
                      },
                    },
                    yAxis: {
                      display: true,
                      title: {
                        display: true,
                        text: "Number of Users",
                      },
                    },
                  },
                },
              }}
              data={{
                labels: weeklyLabels.map((x: string) =>
                  new Date(x).toLocaleDateString("en-GB")
                ),
                datasets: [
                  {
                    label: "Weekly New Users",
                    data: weeklyValues,
                    yAxisID: "yAxis",
                    xAxisID: "xAxis",
                    borderColor: "rgb(244, 99, 5)",
                    backgroundColor: "rgba(244, 99, 5, 0.1)",
                    fill: true,
                  },
                ],
              }}
            />
            <div>
              <div className="more-stats-container">
                <div className="more-stats-title">
                  <div className="more-stats-column">
                    <div className="more-stats-row">
                      <h4>
                        New Accounts This Week (
                        {getWeekBegining(
                          new Date().getTime()
                        ).toLocaleDateString("en-GB")}
                        )
                      </h4>
                      <h4>{usersThisWeek()}</h4>
                    </div>
                    <div className="more-stats-row">
                      <h4>Weekly Average</h4>{" "}
                      <h4>{Math.round(weeklyAverage())}</h4>
                    </div>
                    <div className="more-stats-row">
                      <h4>Highest Week Of User Onboards</h4>
                      <h4>{highestWeek()}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "center",
            height: "50%",
            margin: "5% 0",
          }}
        >
          <div style={{ width: "50%" }}>
            <Pie
              options={options("Gender Demographics")}
              data={{
                labels: genderDemo,
                datasets: [
                  {
                    data: genderDemoValues,

                    backgroundColor: [
                      "#F46305",
                      "#C70039",
                      "#FFC300",
                      "#DAF7A6",
                    ],
                  },
                ],
              }}
            />
          </div>
          <div style={{ width: "50%" }}>
            <Pie
              options={options("Platform Demographics")}
              data={{
                labels: platformDemo,
                datasets: [
                  {
                    data: platformDemoValues,

                    backgroundColor: ["#17202A", "#2ECC71"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </Tab.Pane>
    );
  };

  const TableRow = (props: {
    title: string;
    count?: number;
    interests?: string[];
  }) => {
    return (
      <Table.Row key={Math.random()}>
        <Table.Cell>{props.title}</Table.Cell>
        <Table.Cell>
          {props.count
            ? props.count
            : props.interests
            ? props.interests.join(", ")
            : ""}
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowInterests = (props: {
    title: string;
    interests: string[];
    name: string;
    bio: string;
    dob: number;
    username: string;
    snafles: number;
    email: string;
    createdDate: number;
  }) => {
    return (
      <Table.Row key={Math.random()}>
        {/* <Table.Cell>{props.title}</Table.Cell> */}
        <Table.Cell>{props.email}</Table.Cell>
        <Table.Cell>{props.name}</Table.Cell>
        <Table.Cell>{props.bio}</Table.Cell>
        <Table.Cell>
          {new Date(props.dob)?.toLocaleDateString("en-GB")}
        </Table.Cell>

        <Table.Cell>{props?.username.slice(0, 13)}</Table.Cell>
        {/* <Table.Cell>{props?.snafles}</Table.Cell> */}
        <Table.Cell>{props?.interests?.join(", ")}</Table.Cell>
        <Table.Cell>
          {new Date(props.createdDate)?.toLocaleDateString("en-GB")}
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRows = (props: { data: PopData[] }): any => {
    return props.data.map((x: PopData) => (
      <TableRow key={Math.random()} title={x.title} count={x.count} />
    ));
  };

  const TableRowsInterests = (props: { data: UsersAndInterests[] }): any => {
    return props.data.map((x: UsersAndInterests) => (
      <TableRowInterests
        key={Math.random()}
        email={x.email}
        title={x.username}
        interests={x.interests}
        bio={x.bio}
        dob={x.dob}
        name={x.name}
        username={x.username}
        snafles={x.snafles}
        createdDate={x.createdDate}
      />
    ));
  };

  const TableRowPro = (props: {
    username: string;
    planId: string;
    status: string;
    deviceType: string;
    expiryTimestamp: number;
  }) => {
    return (
      <Table.Row key={Math.random()}>
        <Table.Cell>{props.username}</Table.Cell>
        <Table.Cell>{props.planId}</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
        <Table.Cell>{props.deviceType}</Table.Cell>
        <Table.Cell>
          {props?.expiryTimestamp
            ? new Date(props.expiryTimestamp).toLocaleDateString("en-GB")
            : "No time found"}
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowsPro = (props: { data: ProAccounts[] }): any => {
    return props.data.map((x: ProAccounts) => (
      <TableRowPro
        key={Math.random()}
        username={x.username}
        planId={x.planId}
        status={x.status}
        deviceType={x.deviceType}
        expiryTimestamp={x.expiryTimestamp}
      />
    ));
  };

  const TableRowReferralCode = (props: {
    username: string;
    profileComplete: boolean;
    referralCode: string;
  }) => {
    return (
      <Table.Row key={Math.random()}>
        <Table.Cell>{props.username}</Table.Cell>
        <Table.Cell>{`${
          props?.profileComplete ? "TRUE" : "FALSE"
        } `}</Table.Cell>
        <Table.Cell>{props.referralCode}</Table.Cell>
      </Table.Row>
    );
  };

  const TableRowsReferralCode = (props: { data: any[] }): any => {
    return props.data.map((x: any) => (
      <TableRowReferralCode
        key={Math.random()}
        username={x.username}
        profileComplete={x.profileComplete}
        referralCode={x.referralCode}
      />
    ));
  };

  const Sets = () => {
    return (
      <>
        <Tab.Pane>
          <h2>Popular Sets</h2>
          <p>This can take a while</p>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Set Title</Table.HeaderCell>
                <Table.HeaderCell>Subscription Count</Table.HeaderCell>
                {/* <Table.HeaderCell>Completion Number</Table.HeaderCell>
                <Table.HeaderCell>Completion Percentage</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRows data={popSets} />
            </Table.Body>
          </Table>
        </Tab.Pane>
      </>
    );
  };

  const Interests = () => {
    return (
      <>
        <Tab.Pane>
          <h2>Popular Interests</h2>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Interest Title</Table.HeaderCell>
                <Table.HeaderCell>Interest Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRows data={popInts} />
            </Table.Body>
          </Table>
        </Tab.Pane>
      </>
    );
  };

  const UsersAndInterests = () => {
    return (
      <>
        <Tab.Pane>
          <h2>Users and Interests</h2>
          <p>This can take a while</p>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Bio</Table.HeaderCell>
                <Table.HeaderCell>DOB</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                {/* <Table.HeaderCell>Snafles</Table.HeaderCell> */}
                <Table.HeaderCell>Interests</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRowsInterests data={userInterests} />
            </Table.Body>
          </Table>
        </Tab.Pane>
      </>
    );
  };

  const ProUsers = () => {
    return (
      <>
        <Tab.Pane>
          <h2>Pro Accounts</h2>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Plan</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Device Type</Table.HeaderCell>
                <Table.HeaderCell>Expires</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRowsPro data={proUsers} />
            </Table.Body>
          </Table>
        </Tab.Pane>
      </>
    );
  };

  const ReferralUsers = () => {
    return (
      <>
        <Tab.Pane>
          <h2>Users and Referral Codes</h2>
          <p>This can take a while</p>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Profile Completed</Table.HeaderCell>
                <Table.HeaderCell>Referral Code</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRowsReferralCode data={profileComplete} />
            </Table.Body>
          </Table>
        </Tab.Pane>
      </>
    );
  };

  const panes = [
    {
      menuItem: `Users`,
      render: () => <Users />,
    },
    {
      menuItem: `Sets`,
      render: () => <Sets />,
    },
    {
      menuItem: `Interests`,
      render: () => <Interests />,
    },
    {
      menuItem: `Users and Interests`,
      render: () => <UsersAndInterests />,
    },
    {
      menuItem: `Users and Referral Codes`,
      render: () => <ReferralUsers />,
    },
    {
      menuItem: `Pro Users (${proCount})`,
      render: () => <ProUsers />,
    },
  ];

  return (
    <div>
      <NavBar />
      <div className={"mission-body-container"}>
        {/**
         * main body
         */}
        {/* <h1>Development Complete ETA : {this.state.date}</h1> */}
        <div className={"mission-data"}>
          {/* <h2>Snafler Stats</h2>
            <h5>Most Recent: {this.state.mostRecent}</h5> */}
          {/* <div className={"mission-data-containers-container"}> */}
          {/**
           * data boxes - sets, items, snafles, posts,
           */}
          <div className={"mission-data-containers-container"}>
            <DataContainer
              data={{
                Icon: FaUsers,
                color: "white",
                title: "Users",
                primaryData: users,
                increase:
                  new Date(
                    dailyLabels[dailyLabels.length - 1]
                  ).toLocaleDateString("en-GB") ===
                  new Date().toLocaleDateString("en-GB")
                    ? dailyValues[dailyValues.length - 1]
                    : 0,
              }}
            />

            <DataContainer
              data={{
                Icon: BiMessageDetail,
                color: "white",
                title: "Posts",
                primaryData: posts,
              }}
            />
            <DataContainer
              data={{
                Icon: BsCollectionFill,
                color: "white",
                title: "Sets",
                primaryData: sets,
              }}
            />
          </div>
          <Tab panes={panes} renderActiveOnly={true} />
        </div>
      </div>
    </div>
  );
};

export default Stats;
