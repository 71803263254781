import React, { useCallback, useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Form, Input } from "semantic-ui-react";
import styled from "styled-components";
import { NavBarTopCustom } from "../Navbar";
import { debounce } from "lodash";
import Autocomplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadNewLocation } from "../../redux/location";
import { useHistory } from "react-router-dom";
import { loadCurrentItem } from "../../redux/currentItem";
import { loadCurrentSet } from "../../redux/currentSet";
export const LocationComp = (props: any) => {
  const currentSet = useAppSelector((state) => state.currentSet.data);
  const currentItem = useAppSelector((state) => state.currentItem.data);

  interface ImprovedLocation {
    name?: string;
    formatted_address?: string;
    country_code?: string;
    lng?: number;
    lat?: number;
    photo_reference?: string;
    type?: string;
  }

  const placesUrl = (query: string) =>
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&key=AIzaSyCnDt8uFLm2QRWPh2OzkJjAH4UQEL9sJZ4`;

  //debounce
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState("");
  const [places, setPlaces] = useState([] as any);

  //api call
  const predictGoogleMaps = async (url: string, country?: boolean) => {
    const data = await fetch(url)
      .then((data) => data.json())
      .then((data) => {
        let arr = [];
        for (let i in data.predictions) {
          console.log(data);
          try {
            arr.push({
              name: data.predictions[i].description,
              formatted_address:
                data.predictions[i].structured_formatting.main_text,
              photo_reference: "",
            });
          } catch (err) {
            console.log(err);
          }
        }
        return arr;
      });

    return data! as ImprovedLocation[];
  };

  //redux assign location

  const SearchBarContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 5 0%;
  `;

  const LocationOption = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid var(--light-grey);
    margin: 0;
    padding: 5%;
  `;

  const LocationDetails = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
  `;

  const Small = styled.p`
    font-size: 0.7em;
  `;
  const LocationOptions = SearchBarContainer;

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyCnDt8uFLm2QRWPh2OzkJjAH4UQEL9sJZ4",
  });
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    let arr = [];
    if (placePredictions.length) {
      for (let i in placePredictions) {
        arr.push({
          name: placePredictions[i].structured_formatting.main_text,
          formatted_address: placePredictions[i].description,
        });
      }
      setPlaces(arr);
    }
  }, [placePredictions]);

  const handler = (event: any) => {
    let value = event.target.value;
    getPlacePredictions({ input: value });
  };

  const dispatch = useAppDispatch();
  const history = useHistory();
  return (
    <div>
      <div>
        {/**
         * Navbar top
         */}
        <NavBarTopCustom
          buttonLeft=""
          title={`Add Set Location`}
          buttonRight=""
        />
      </div>
      <div className="location-containers">
        {/**
         * Search bar
         */}

        <Input
          key="AutocompleteId"
          id="AutocompleteId"
          placeholder="Search Locations"
          style={{ margin: "5%" }}
          onChange={handler}
          loading={isPlacePredictionsLoading}
        />
      </div>
      <div className="location-containers">
        {/**
         * list of all the search results
         */}
        <div
          onClick={() => {
            dispatch(
              loadNewLocation({
                name: "Global",
                type: "GLOBAL",
                formatted_address: "Global",
              })
            );

            history.goBack();
          }}
          style={{
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            borderBottom: "1px solid var(--light-grey)",
            margin: 0,
            padding: "5%",
          }}
        >
          <div style={{ flex: 1 }}>
            <HiOutlineLocationMarker size={22} />
          </div>
          <LocationDetails style={{ flex: 6 }}>
            <div>Global</div>
          </LocationDetails>
        </div>

        {places.length > 0 ? (
          places.map((item: ImprovedLocation) => (
            <div
              onClick={() => {
                const loc = { location: { ...item, type: "PLACE" } };

                dispatch(loadNewLocation({ ...item, type: "PLACE" }));
                dispatch(loadCurrentItem({ ...currentItem, ...loc }));
                dispatch(loadCurrentSet({ ...currentSet, ...loc }));
                history.goBack();
              }}
              style={{
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
                borderBottom: "1px solid var(--light-grey)",
                margin: 0,
                padding: "5%",
              }}
              key={item.formatted_address}
            >
              <div style={{ flex: 1 }}>
                <HiOutlineLocationMarker size={22} />
              </div>
              <div
                style={{
                  flex: 6,
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                }}
              >
                <div>{item.name}</div>
                <p style={{ fontSize: "0.7em" }}>{item.formatted_address}</p>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
