import React, { useEffect, useState } from "react";
import { UploadLevel } from "../../uploadToS3";
import { NavBar } from "../Navbar";
import { Storage } from "aws-amplify";
import authorization from "../../authorization";
import { Table } from "semantic-ui-react";
import { useHistory } from "react-router";
import { Dropdown } from "semantic-ui-react";

const ViewReport = (props: any) => {
  const [reportId, setReportId] = useState(props.match.params.reportId);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [currentReport, setCurrentReport] = useState<any>();
  const [modComments, setModComments] = useState("");
  const [dropDown, setDropDown] = useState("");
  const [currentReportType, setCurrentType] = useState("");
  const [reportContent, setReportContent] = useState<any>();
  const history = useHistory();

  type MediaType =
    | "post"
    | "profile_cover"
    | "profile_image"
    | "profile_thumbnail"
    | "report_problem"
    | "feedback"
    | "verification";
  const filepathHandler = (
    idPlusMetadata: string,

    type: MediaType
  ): string => {
    return `${idPlusMetadata}_${type}`;
  };

  const moderatorComments = () =>
    currentReportType === "VER"
      ? [
          {
            key: "Meets Requirements",
            text: `Meets Requirements`,
            value: "Meets Requirements",
          },
          {
            key: "Not a Perfect Fit",
            text: `Not a Perfect Fit`,
            value: "Not a Perfect Fit",
          },
          {
            key: "Does not Meet Requirements",
            text: `Does not Meet Requirements`,
            value: "Does not Meet Requirements",
          },
        ]
      : [
          {
            key: "FalsePositive",
            text: "False Positive",
            value: "False Positive",
          },
          {
            key: "Inappropiate",
            text: "Inappropriate",
            value: "Inappropriate Content",
          },
          { key: "SexualContent", text: "Sexual", value: "Sexual Content" },
          {
            key: "OffensiveContent",
            text: "Offensive",
            value: "Offensive Content",
          },
        ];

  const getReportData = async (userSub: string, sourceId: string) => {
    console.log(userSub);

    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationReport?userSub=${userSub}&sourceId=${sourceId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        setCurrentReport(res);
        return res;
      });
    await getPresignedUrl(data?.cognitoid, data?.sourceId, data?.sourceType);
  };

  const getReport = async (userSub: string, sourceId: string) => {
    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getReport?userSub=${userSub}&sourceId=${sourceId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCurrentReport(res);
        return res;
      });
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}`;
    if (data.sourceType === "SET") {
      await fetch(`${url}/getSet?id=${data.sourceId}`, {
        method: "GET",
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "ITEM") {
      await fetch(`${url}/getSetItem?id=${data.sourceId}`, {
        method: "GET",
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "POST") {
      await fetch(`${url}/getPost?postId=${data.sourceId}`, {
        method: "GET",
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          setReportContent(res);
        });
    } else if (data.sourceType === "COMMENT") {
      //   await fetch(`${url}/getComment?id=${data.sourceId}`)
      //     .then((res) => res.json())
      //     .then((res) => {
      //       setReportContent(res);
      //     });
    }
  };

  const getCCPR = async (userSub: string, requestId: string) => {
    const data = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPR?userSub=${userSub}&requestId=${requestId}`,
      { method: "GET", headers: { ...(await authorization()) } }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setCurrentReport(res);
        return res;
      });
    await getPresignedUrl(
      data?.cognitoId,
      filepathHandler(data?.userSub, "verification"),
      "set"
    );
  };

  const getPresignedUrl = async (
    identityId: string,
    assetName: string,
    type:
      | "post"
      | "set"
      | "item"
      | "profile_thumbnail"
      | "profile_cover"
      | "profile_image"
  ) => {
    console.log({ identityId, assetName });

    return await Storage.get(
      `${assetName}${
        type === "post"
          ? "_IMAGE_1"
          : type === "profile_thumbnail" ||
            type === "profile_cover" ||
            type == "profile_image"
          ? `_${type}`
          : ""
      }.jpg`,
      {
        identityId,
        level: UploadLevel.PROTECTED,
      }
    )
      .then((data: any) => {
        if (typeof data === "string") {
          setImageUrl(data);
        } else {
          console.log("Did not return a string: " + data);
        }
      })
      .catch((err: any) => console.log(err));
  };

  const updateModeration = async (
    userSub: string,
    sourceId: string,
    status: "ACCEPTED" | "REVIEW" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    sourceType: string
  ) => {
    const body = {
      userSub,
      sourceId,
      status,
      moderatorComments: `${moderatorComments} ${dropDowncomments}`,
      sourceType,
    };
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}updateModerationReport`,
      {
        method: "PUT",
        headers: { ...(await authorization()) },
        body: JSON.stringify(body),
      }
    );
    setTimeout(() => {
      history.goBack();
    });
  };

  const updateReport = async (
    userSub: string,
    sourceId: string,
    status: "ACCEPTED" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    sourceType: string
  ) => {
    const body = {
      userSub,
      sourceId,
      status,
      moderatorComments: `${moderatorComments} ${dropDowncomments}`,
      sourceType,
    };
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}updateReport`, {
      method: "PUT",
      headers: { ...(await authorization()) },
      body: JSON.stringify(body),
    });
    setTimeout(() => {
      history.goBack();
    });
  };

  const updateCCPR = async (
    userSub: string,
    requestId: string,
    status: "ACCEPTED" | "REJECTED",
    moderatorComments: string,
    dropDowncomments: string,
    type: string
  ) => {
    const body = {
      userSub,
      requestId,
      status,
      moderatorComments: `${moderatorComments}. -> ${dropDowncomments}`,
      type,
    };

    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}updateCCPR`, {
      method: "PUT",
      headers: { ...(await authorization()) },
      body: JSON.stringify(body),
    });
    setTimeout(() => {
      history.goBack();
    });
  };

  const TableRowReportMOD = (props: {
    sourceId: string;
    username: string;
    userSub: string;
    mediaType: string;
    moderationTimestamp: string;
    reason: string;
    sourceType: string;
    status: string;
    modifiedBy: string;
    reviewedTimestamp: string;
    detectionLabels: any;
    moderatorComments: string;
    mod: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <>
        <Table.Row>
          {/* <Table.Cell>{props.username}</Table.Cell> */}
          <Table.Cell>Username</Table.Cell>
          <Table.Cell>{props.username}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Source Type</Table.Cell>
          <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Media Type</Table.Cell>
          <Table.Cell>{props.mediaType}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Moderation Reason</Table.Cell>
          <Table.Cell>{props.reason}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Moderation Timestamp</Table.Cell>
          <Table.Cell>
            {new Date(props?.moderationTimestamp).toLocaleString("en-GB")}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Moderation Status</Table.Cell>
          <Table.Cell>{props.status}</Table.Cell>
        </Table.Row>
        {props.reviewedTimestamp ? (
          <>
            {" "}
            <Table.Row>
              <Table.Cell>Reviewed Timestamp</Table.Cell>
              <Table.Cell>
                {new Date(props?.reviewedTimestamp).toLocaleString("en-GB")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reviewer</Table.Cell>
              <Table.Cell>{props.mod}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Comments</Table.Cell>
              <Table.Cell>{props.moderatorComments}</Table.Cell>
            </Table.Row>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const SocialMediaAccountsTable = (props: { socialMediaAccounts: any }) => {
    console.log(props);
    return (
      <>
        <Table.Row>
          <Table.Cell>Instagram</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.instagram}>
              {props.socialMediaAccounts?.instagram}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Facebook</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.facebook}>
              {props.socialMediaAccounts?.facebook}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Twitter</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.twitter}>
              {props.socialMediaAccounts?.twitter}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Medium</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.medium}>
              {props.socialMediaAccounts?.medium}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>LinkedIn</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.linkedIn}>
              {props.socialMediaAccounts?.linkedIn}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Personal Website</Table.Cell>
          <Table.Cell>
            <a href={props.socialMediaAccounts?.website}>
              {props.socialMediaAccounts?.website}
            </a>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Other Sites</Table.Cell>
          <Table.Cell>
            {props?.socialMediaAccounts?.other?.map((x: string) => (
              <a href={x}>{x},</a>
            ))}
          </Table.Cell>
        </Table.Row>
      </>
    );
  };

  const capitalizeFirstLetter = (string: string) =>
    string?.charAt(0).toUpperCase() + string?.slice(1);

  const TableRowReportVER = (props: {
    requestId: string;
    username: string;
    userSub: string;
    type: string;
    moderationTimestamp: string;
    fullName: string;
    requestCategory: string;
    status: string;
    modifiedBy: string;
    reviewedTimestamp: string;
    detectionLabels: any;
    moderatorComments: string;
    mod: string;
    audience: string;
    timestamp: number;
    documentType: string;
    socialMediaAccount: any;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <>
        <Table.Row>
          {/* <Table.Cell>{props.username}</Table.Cell> */}
          <Table.Cell>Username</Table.Cell>
          <Table.Cell>{props.username}</Table.Cell>
        </Table.Row>
        {props?.type === "VERIFICATION" ? (
          <Table.Row>
            <Table.Cell>Request Category</Table.Cell>
            <Table.Cell>{props.requestCategory?.toUpperCase()}</Table.Cell>
          </Table.Row>
        ) : (
          <></>
        )}

        <Table.Row>
          <Table.Cell>Request Type</Table.Cell>
          <Table.Cell>{props.type}</Table.Cell>
        </Table.Row>
        {props?.type === "VERIFICATION" ? (
          <>
            <Table.Row>
              <Table.Cell>Full Name</Table.Cell>
              <Table.Cell>{props.fullName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Audience</Table.Cell>
              <Table.Cell>{props.audience}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Document Type</Table.Cell>
              <Table.Cell>
                <a href={imageUrl} download>
                  {props?.documentType?.toUpperCase()}
                </a>
              </Table.Cell>
            </Table.Row>
          </>
        ) : (
          <></>
        )}

        <Table.Row>
          <Table.Cell>Request Timestamp</Table.Cell>
          <Table.Cell>
            {new Date(props?.timestamp).toLocaleString("en-GB") || ""}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Request Status</Table.Cell>
          <Table.Cell>{props.status}</Table.Cell>
        </Table.Row>

        {props.reviewedTimestamp ? (
          <>
            {" "}
            <Table.Row>
              <Table.Cell>Reviewed Timestamp</Table.Cell>
              <Table.Cell>
                {new Date(props?.reviewedTimestamp).toLocaleString("en-GB")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reviewer</Table.Cell>
              <Table.Cell>{props.mod}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Comments</Table.Cell>
              <Table.Cell>{props.moderatorComments}</Table.Cell>
            </Table.Row>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const TableRowReportREP = (props: {
    sourceId: string;
    username: string;
    userSub: string;
    sourceType: string;
    reportCategory: string;
    modifiedBy: string;
    reviewedTimestamp: string;
    moderatorComments: string;
    mod: string;
    timestamp: number;
    userMessage: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <>
        <Table.Row>
          {/* <Table.Cell>{props.username}</Table.Cell> */}
          <Table.Cell>Reporter</Table.Cell>
          <Table.Cell>{props.username}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Request Category</Table.Cell>
          <Table.Cell>{props.reportCategory?.toUpperCase()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Source Type</Table.Cell>
          <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>User Report Reason</Table.Cell>
          <Table.Cell>{props.userMessage}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>Request Timestamp</Table.Cell>
          <Table.Cell>
            {new Date(props?.timestamp).toLocaleString("en-GB") || ""}
          </Table.Cell>
        </Table.Row>

        {props.reviewedTimestamp ? (
          <>
            {" "}
            <Table.Row>
              <Table.Cell>Reviewed Timestamp</Table.Cell>
              <Table.Cell>
                {new Date(props?.reviewedTimestamp).toLocaleString("en-GB")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reviewer</Table.Cell>
              <Table.Cell>{props.mod}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Comments</Table.Cell>
              <Table.Cell>{props.moderatorComments}</Table.Cell>
            </Table.Row>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const TableRowsReport = (props: { data: any }): JSX.Element => {
    if (currentReportType === "MOD") {
      return <TableRowReportMOD {...props.data} />;
    } else if (currentReportType === "VER") {
      return <TableRowReportVER {...props.data} />;
    } else {
      return <TableRowReportREP {...props.data} />;
    }
  };

  const dropDownValue = (event: any, { value }: any) => {
    setDropDown(value);
  };

  useEffect(() => {
    const ids = reportId.split("_");
    if (ids[0] === "MOD") {
      getReportData(`${ids[1]}_${ids[2]}`, ids[3]);
    } else if (ids[0] === "REP") {
      getReport(ids[1], ids[2]);
    } else if (ids[0] === "VER") {
      getCCPR(ids[1], ids[2]);
    }
    setCurrentType(ids[0]);
  }, []);

  return (
    <>
      <NavBar />
      <div className="mod-main-conatiner">
        <div className="mod-main-flex">
          <div className="mod-right">
            <h2>Report Details</h2>
            <Table definition celled striped>
              <Table.Body>
                <TableRowsReport data={currentReport} />
              </Table.Body>
            </Table>

            <h5>Moderator Comments</h5>
            <Dropdown
              placeholder="Moderator Comments"
              fluid
              multiple
              selection
              options={moderatorComments()}
              onChange={dropDownValue}
            />
            <textarea
              className="mod-textarea"
              placeholder="Any extra comments"
              onChange={(text) => {
                setModComments(text.target.value);
                console.log(text.target.value);
              }}
            ></textarea>
            <div className="mod-main-flex">
              <button
                className="mod-button green"
                onClick={() => {
                  currentReportType === "MOD"
                    ? updateModeration(
                        currentReport?.userSub,
                        currentReport?.sourceId,
                        "ACCEPTED",
                        modComments,
                        dropDown,
                        currentReport?.sourceType
                      )
                    : currentReportType === "VER"
                    ? updateCCPR(
                        currentReport?.userSub,
                        currentReport?.requestId,
                        "ACCEPTED",
                        modComments,
                        dropDown,
                        currentReport?.type
                      )
                    : updateReport(
                        currentReport?.userSub,
                        currentReport?.sourceId,
                        "ACCEPTED",
                        modComments,
                        dropDown,
                        currentReport?.sourceType?.toUpperCase()
                      );
                }}
              >
                {currentReportType === "MOD"
                  ? "Accept Image"
                  : currentReportType === "VER"
                  ? "Accept Verification"
                  : `Accept Content`}
              </button>
              <button
                className="mod-button orange"
                onClick={() => {
                  currentReportType === "MOD"
                    ? updateModeration(
                        currentReport?.userSub,
                        currentReport?.sourceId,
                        "REJECTED",
                        modComments,
                        dropDown,
                        currentReport?.sourceType
                      )
                    : currentReportType === "VER"
                    ? updateCCPR(
                        currentReport?.userSub,
                        currentReport?.requestId,
                        "REJECTED",
                        modComments,
                        dropDown,
                        currentReport?.type
                      )
                    : updateReport(
                        currentReport?.userSub,
                        currentReport?.sourceId,
                        "REJECTED",
                        modComments,
                        dropDown,
                        currentReport?.sourceType?.toUpperCase()
                      );
                }}
              >
                {currentReportType === "MOD"
                  ? "Reject Image"
                  : currentReportType === "VER"
                  ? "Decline Verification"
                  : `Block Content`}
              </button>
              {currentReportType === "MOD" ? (
                <button
                  className="mod-button red"
                  onClick={() => {
                    updateModeration(
                      currentReport?.userSub,
                      currentReport?.sourceId,
                      "REJECTED",
                      modComments,
                      dropDown,
                      currentReport?.sourceType
                    );
                  }}
                >
                  Reject{" "}
                  {currentReportType === "MOD" ? "Image & Ban User" : "Report"}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="mod-left">
            <div className={"mod-image-container"}>
              {currentReportType === "MOD" ? (
                <img
                  src={`${imageUrl}`}
                  alt="Moderation Image"
                  className="mod-image"
                />
              ) : (
                <>
                  {currentReportType === "VER" &&
                  currentReport?.type === "VERIFICATION" ? (
                    <>
                      <h2>Document Links</h2>
                      <a href={imageUrl} download className="mod-files">
                        {currentReport?.documentType?.toUpperCase()}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                  {currentReportType === "REP" ? (
                    <>
                      <h2>Content</h2>
                      {currentReport?.sourceType === "ITEM" ||
                      currentReport?.sourceType === "SET" ? (
                        <div className={"mod-item"}>
                          <h2>{reportContent?.title}</h2>
                          <h3>Description</h3>
                          <p>{reportContent?.description}</p>
                          <h3>Created By</h3>
                          <p>
                            {reportContent?.createdBy ===
                            "269e2220-2529-4fbd-a531-7ce5107f6330"
                              ? "Snafler"
                              : reportContent?.createdBy}
                          </p>
                          {reportContent?.createdTimestamp ? (
                            <>
                              <h3>Created on</h3>
                              <p>
                                {new Date(
                                  reportContent?.createdTimestamp
                                ).toLocaleString("en-GB")}
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                          <h3>Tags</h3>
                          <p>
                            {reportContent?.tags.map((x: string) => `#${x}, `)}
                          </p>
                          <div>
                            <h3>Location</h3>
                            <p>
                              {reportContent?.location?.name}, <br />
                              {reportContent?.location?.formatted_address},{" "}
                              <br />
                              {reportContent?.location?.type}, <br />
                              {reportContent?.location?.country_code}
                              <br />
                            </p>
                          </div>
                          <h3>Image</h3>
                          <a
                            href={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${reportContent?.id}.jpg`}
                            download
                            target="_blank"
                          >
                            <img
                              src={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${reportContent?.id}.jpg`}
                              className="mod-content-image"
                            />
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {currentReport?.sourceType === "POST" ? (
                        reportContent?.postType === undefined ||
                        reportContent?.postType === null ? (
                          <>
                            <h1>No content</h1>
                          </>
                        ) : (
                          <div className={"mod-item"}>
                            {reportContent?.postType === "VIDEO" ? (
                              <video
                                className="mod-content-image"
                                controls
                                autoPlay
                              >
                                <source
                                  src={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${reportContent?.id}_VIDEO_1.mp4`}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <></>
                            )}
                            {reportContent?.postType === "IMAGE" ? (
                              <div>
                                <a
                                  href={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${reportContent?.id}_IMAGE_1.jpg`}
                                  download
                                  target="_blank"
                                >
                                  <img
                                    src={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${reportContent?.id}_IMAGE_1.jpg`}
                                    className="mod-content-image"
                                  />
                                </a>
                              </div>
                            ) : (
                              <></>
                            )}
                            <h3>Post Caption</h3>
                            <p>{reportContent?.description}</p>
                            <h3>Post Stats</h3>
                            <p>
                              Like Count: {reportContent?.likeCount},<br />
                              Comment Count: {reportContent?.commentCount}
                            </p>
                            <h3>Date Created</h3>
                            <p>
                              {new Date(reportContent?.date).toLocaleString(
                                "en-GB"
                              )}
                            </p>
                            <h3>Post Status</h3>
                            <p>{reportContent?.status}</p>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {currentReportType === "VER" ? (
                    <>
                      <h2 style={{ margin: "10% 0 0 0" }}>
                        Social Media Accounts
                      </h2>
                      <Table definition celled striped>
                        <Table.Body>
                          <SocialMediaAccountsTable {...currentReport} />
                        </Table.Body>
                      </Table>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewReport;
