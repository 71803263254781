import { Data } from "../../components/Images";

interface imageActions {
  LOAD_NEW_IMAGE: string;
  DELETE_DATA: string;
}

const imageActionType: imageActions = {
  LOAD_NEW_IMAGE: "LOAD_NEW_IMAGE",
  DELETE_DATA: "DELETE_DATA",
};

export const loadNewImage = (data: Data) => ({
  type: imageActionType.LOAD_NEW_IMAGE,
  payload: data,
});

export const deleteImage = () => ({
  type: imageActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

const imageReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case imageActionType.LOAD_NEW_IMAGE:
      return { ...state, data: action.payload };
    case imageActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default imageReducer;
