import authorization from "../../authorization";

const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getCategories`;

interface Category {
  id: string;
  name: string;
}

interface categoryActions {
  FETCH_CATEGORY_START: string;
  FETCH_CATEGORY_SUCCESS: string;
  FETCH_CATEGORY_FAIL: string;
}

const categoryActionTypes: categoryActions = {
  FETCH_CATEGORY_START: "FETCH_CATEGORY_START",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  FETCH_CATEGORY_FAIL: "FETCH_CATEGORY_FAIL",
};

export const fetchCategoryStart = () => ({
  type: categoryActionTypes.FETCH_CATEGORY_START,
});

export const fetchCategorySuccess = (data: Category[]) => ({
  type: categoryActionTypes.FETCH_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchCategoryFail = (errorMessage: string) => ({
  type: categoryActionTypes.FETCH_CATEGORY_FAIL,
  payload: errorMessage,
});

const INITIAL_STATE = {
  data: [],
  isFetching: false,
  errorMessage: undefined,
};

export const fetchCategoriesAsync = () => {
  return async (dispatch: any) => {
    dispatch(fetchCategoryStart());
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((response) => response.json())
      .then((jsonData) =>
        dispatch(fetchCategorySuccess(jsonData! as Category[]))
      )
      .catch((err) => dispatch(fetchCategoryFail(err)));
  };
};

const catReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case categoryActionTypes.FETCH_CATEGORY_START:
      return { ...state, isFetching: true };
    case categoryActionTypes.FETCH_CATEGORY_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case categoryActionTypes.FETCH_CATEGORY_FAIL:
      return { ...state, isFetching: false, errorMessage: action.payload };

    default:
      return state;
  }
};

export default catReducer;
