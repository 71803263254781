import authorization from "../../authorization";

interface currentDiscoverActions {
  LOAD_DISCOVER: string;
  DELETE_DATA: string;
}

interface DiscoverInput {
  id?: string;
  topWeeklySets: { id: string; title: string }[];
  topDailySets: { id: string; title: string }[];
  startDate?: string;
  endDate?: string;
  trendingSnafleSets: { id: string; title: string }[];
  newOnSnafler?: string[];
  topAmbassadors?: string[];
}

const discoverActionType: currentDiscoverActions = {
  LOAD_DISCOVER: "LOAD_DISCOVER",
  DELETE_DATA: "DELETE_DATA",
};

export const loadDiscoverData = (data: DiscoverInput) => ({
  type: discoverActionType.LOAD_DISCOVER,
  payload: data,
});

export const deleteDiscoverData = () => ({
  type: discoverActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

export const fetchDiscoverData = (url: string) => {
  console.log(url);
  return async (dispatch: any) => {
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((response) => response.json())
      .then((jsonData) =>
        dispatch(loadDiscoverData(jsonData! as DiscoverInput))
      );
  };
};

const discoverReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case discoverActionType.LOAD_DISCOVER:
      return { ...state, data: action.payload };
    case discoverActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default discoverReducer;
