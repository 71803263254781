import styled from "styled-components";

export const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-flow: row;
  padding: 0 5%;
  padding-bottom: 5%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100vw;

  border-top: 1px solid var(--light-grey);

  @media (min-width: 750px) {
    display: none;
  }
`;

export const BottomNavTab = styled.div`
  padding: 5%;
`;
