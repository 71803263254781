import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks";
import { Button, Dropdown, Input } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import authorization from "../../authorization";

export const ActivityImageChooser = (props: any) => {
  const catActData = useAppSelector((state) => state.currentCatAct.data);
  const [imageValue, setImageValue] = useState<string>(catActData.iconType);
  const [inputValue, setInputValue] = useState<string>(catActData.assetName);
  const history = useHistory();
  library.add(fas, fab, far);

  const GenerateIcon = (props: { inputValue: string }) => (
    //@ts-ignore
    <FontAwesomeIcon icon={[imageValue, inputValue]} size="10x" />
  );

  const options = [
    { key: "fas", text: "Solid", value: "fas" },
    { key: "far", text: "Regular", value: "far" },
    { key: "fab", text: "Brands", value: "fab" },
  ];

  const submitIcon = async () => {
    console.log(catActData.id);

    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}updateActivity`;
    await fetch(url, {
      method: "PUT",

      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: catActData.id,
        assetName: inputValue,
        iconType: imageValue,
      }),
    }).then(() => {
      history.goBack();
    });
  };

  return (
    <div>
      <div className="nav-bar-top">
        {/**
         * Header
         *
         */}
        <h3>{catActData.name}</h3>
      </div>

      <div className="activity-image-container">
        <div className="inputContainer">
          <a
            style={{ fontSize: "1.5em" }}
            className="links"
            href="https://fontawesome.com/v5.15/icons?d=gallery&p=2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find Icons on FontAwesome
          </a>
        </div>
        <p>Copy and paste the icon name into the textbox</p>

        <GenerateIcon inputValue={inputValue} />
        <div className="inputContainer">
          <Input
            placeholder={"Paste icon name"}
            value={inputValue}
            onChange={(nextValue) => setInputValue(nextValue.target.value)}
          />
          <select
            value={imageValue}
            required={true}
            onChange={(val) => {
              setImageValue(val.target.value);
            }}
          >
            <option selected={true} disabled={true}>
              Select a Category
            </option>
            {options.map(
              (item: { key: string; text: string; value: string }) => (
                <option key={item.key} value={item.value}>
                  {item.text}
                </option>
              )
            )}
          </select>
        </div>
        <div className="inputContainer">
          <Button color="orange" onClick={submitIcon}>
            Upload Icon
          </Button>
        </div>
      </div>
    </div>
  );
};
