import styled from "styled-components";

export const Floating = styled.div`
  position: fixed;
  width: 100%;
  bottom: 10%;
  right: 0%;
  left: 40%;
  text-align: center;
`;

export const MenuContainerHome = styled.div`
  padding: 0% 0 20% 0;
  margin: 0 5%;
`;
