import authorization from "../../authorization";
import { ImprovedLocation } from "../location";

interface currentItemActions {
  LOAD_CURRENT_ITEM: string;
  DELETE_DATA: string;
}

interface currentItem {
  title?: string;
  description?: string;
  location?: ImprovedLocation;
  activityType?: string;
  image?: { author: string; url: string; downloadLink: string };
  base64?: string;
  categoryId?: string;
  geofence?: boolean;
}

const currentItemActionType: currentItemActions = {
  LOAD_CURRENT_ITEM: "LOAD_CURRENT_ITEM",
  DELETE_DATA: "DELETE_DATA",
};

export const loadCurrentItem = (data: currentItem) => ({
  type: currentItemActionType.LOAD_CURRENT_ITEM,
  payload: data,
});

export const deleteCurrentItem = () => ({
  type: currentItemActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

export const fetchCurrentItemData = (url: string) => {
  console.log(url);
  return async (dispatch: any) => {
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((response) => response.json())
      .then((jsonData) => dispatch(loadCurrentItem(jsonData! as currentItem)));
  };
};

const currentItemReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case currentItemActionType.LOAD_CURRENT_ITEM:
      return { ...state, data: action.payload };
    case currentItemActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default currentItemReducer;
