import Auth from "@aws-amplify/auth";

export default async () => {
  const JWT_TOKEN = await Auth.currentSession().then((data) =>
    data.getIdToken().getJwtToken()
  );
  return {
    Authorization: JWT_TOKEN,
  };
};
