import React, { useEffect, useRef, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { Button, Checkbox, Form } from "semantic-ui-react";
import styled from "styled-components";
import { NavBarTopCustom } from "../Navbar";
import { FiCamera } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteCurrentItem,
  fetchCurrentItemData,
  loadCurrentItem,
} from "../../redux/currentItem";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteLocation } from "../../redux/location";
import { Data } from "../Images";
import { deleteImage } from "../../redux/image";
import { deleteBase64 } from "../../redux/base64";
import Cliploader from "react-spinners/ClipLoader";
import authorization from "../../authorization";
import {
  getBase64Buffer,
  getBufferFromFile,
  uploadDirectToS3,
  UploadLevel,
} from "../../uploadToS3";
import { Auth } from "aws-amplify";

export const EditItemForm = (props: any) => {
  const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getSetItem?id=${props.match.params.id}`;
  const dispatch = useAppDispatch();
  const currentItem = useAppSelector((state) => state.currentItem.data);
  const [itemTitle, setItemTitle] = useState<string>(currentItem?.title || "");
  const [itemDesc, setItemDesc] = useState<string>("");
  const [ItemActivityType, setItemActivityType] = useState("");
  const [user, setUser] = useState("");

  Auth.currentSession().then(async (res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    setUser(res.getIdToken().payload.sub);
  });
  const [loaded, setLoaded] = useState(false);

  const [itemImage, setItemImage] = useState<Data>({
    author: "",
    url: "",
    downloadLink: "",
  });

  const [base64, setBase64] = useState("");
  const [geofence, setGeofence] = useState(currentItem?.geofence || false);

  const image = useAppSelector((state) => state.image.data) || {
    author: "",
    url: "",
    downloadLink: "",
  };
  const base64Data = useAppSelector((state) => state.base64.data);

  const [location, setLocation] = useState({
    name: "",
    formatted_address: "",
    type: "",
  });

  const getItemData = async () => {
    //dispatch(fetchCurrentItemData(url));
  };

  /*setTimeout(() => {
    setItemTitle(currentItem?.title || "");
    setItemDesc(currentItem?.description || "");
    setItemActivityType(currentItem?.activityType || "");
    setLocation(currentItem?.location);
  }, 100);*/

  useEffect(() => {
    getItemData();
  }, []);

  const currentLocation = useAppSelector((state) => state.location.data);

  useEffect(() => {
    setItemTitle(currentItem?.title);
    setItemDesc(currentItem?.description);
    setItemActivityType(currentItem?.activityType);
    setLocation(currentLocation || currentItem?.location);
    setItemImage(image || currentItem?.image);
    setBase64(base64Data);
    setGeofence(currentItem?.geofence || false);
  }, []);

  //const location = useAppSelector((state) => state.location.data);
  const Small = styled.p`
    font-size: 0.8em;
    color: var(--grey-color);
  `;

  const LocationDiv = styled.div`
    display: flex;
    flex-flow: row;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    align-items: center;
    padding: 3% 5%;
    justify-content: space-between;
  `;

  const PhotoContainer = styled.div`
    display: flex;
    flex-flow: column;
    background-color: var(--light-grey);
    justify-content: center;
    align-items: center;
    height: 35vh;
  `;

  const createSetItemUrl = `${process.env.REACT_APP_AWS_ENDPOINT1}updateItem`;

  /*const fetchItemData = async (id: string) => {
    return await fetch(url)
      .then((data) => data.json())
      .then((json) => {
        setItemTitle(json.title);
        setItemDesc(json.description);
        setItemActivityType(json.activityType);
        setLocation(json.location);
      });
  };*/

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  const editItem = async (event: any) => {
    event.preventDefault();
    let data = {
      setId: currentItem.setId,
      itemId: currentItem.itemId,
      title: itemTitle,
      description: itemDesc,
      location,
      hasImage: itemImage?.author?.length > 0 || base64 ? true : false,
      geofence,
    };
    console.log(data);

    await fetch(createSetItemUrl, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => console.log(data))
      .then(() => {
        dispatch(deleteLocation());
        dispatch(deleteCurrentSet());
        dispatch(deleteCurrentItem());
        dispatch(deleteImage());
        dispatch(deleteBase64());
      });
    console.log({ itemImage, image, base64Data });
    if (itemImage?.author?.length > 0) {
      await uploadImage(image);
    } else if (base64Data) {
      await uploadBase64(base64Data);
    }

    setTimeout(() => {
      history.goBack();
    }, 1500);
  };

  const deleteItem = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}deleteItem`;

    const data = {
      id: currentItem.setId,
      itemId: currentItem.itemId,
    };
    console.log(data);
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  const uploadBase64 = async (base64: string) => {
    const data = await getBufferFromFile(base64);
    console.log(props.match.params.id);
    await uploadDirectToS3(
      `${props.match.params.id}.jpg`,
      data,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: currentItem.setId,
        sourceId: props.match.params.id,
      },
      UploadLevel.PUBLIC
    );
  };

  const uploadImage = async (data: {
    author: string;
    url: string;
    downloadLink: string;
  }) => {
    const res = await getBase64Buffer(data);
    await uploadDirectToS3(
      `${props.match.params.id}.jpg`,
      res,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: currentItem.setId,
        sourceId: props.match.params.id,
        author: data.author,
        downloadLink: data.downloadLink,
      },
      UploadLevel.PUBLIC
    );
  };

  const handleCheckbox = (event: any, data: any) => {
    // console.log(data);
    setGeofence(data.checked);
  };

  return (
    <div>
      <NavBarTopCustom buttonLeft="Discard" title="Edit Item" buttonRight="" />
      <Form style={{ paddingBottom: "20%" }} onSubmit={editItem}>
        <Form.Field>
          <div
            onClick={() => {
              history.push("/editSnafleItem/setImage");
            }}
            style={
              image?.url.length > 0
                ? {
                    backgroundImage: `url(${itemImage.url})`,
                    backgroundColor: "transparent",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "40vh",
                  }
                : {}
              /*file.length > 0
                ? {
                    backgroundImage: `url(${file})`,
                    backgroundColor: "transparent",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }
                : {
                    display: "flex",
                    flexFlow: "column",
                    backgroundColor: "var(--light-grey)",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35vh",
                  }*/
            }
            className="placeholder-image"
          >
            <FiCamera
              size={80}
              color="#a6a6a6"
              style={image?.url.length > 0 ? { display: "none" } : {}}
            />
            <p
              style={
                image?.url.length > 0
                  ? { display: "none" }
                  : { color: "#a6a6a6" }
              }
            >
              Touch to add an item image
            </p>
          </div>
        </Form.Field>
        <div className="custom-item-form">
          <Form.Field required>
            <label>Title</label>
            <input
              required={true}
              type="text"
              id="ItemTitle"
              name="ItemTitle"
              placeholder="What is the title of your item?"
              value={itemTitle}
              onChange={(val) => {
                setItemTitle(val.target.value);
                dispatch(
                  loadCurrentItem({ ...currentItem, title: val.target.value })
                );
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Description</label>
            <textarea
              required={true}
              id="ItemDesc"
              className=""
              name="ItemDesc"
              placeholder="What does you item involve? Include rules, instructions or tips!"
              value={itemDesc}
              onChange={(val) => {
                setItemDesc(val.target.value);
                dispatch(
                  loadCurrentItem({
                    ...currentItem,
                    description: val.target.value,
                  })
                );
              }}
              style={{ resize: "none" }}
            ></textarea>
          </Form.Field>
          <Form.Field>
            <label>Location</label>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                border: "1px solid var(--light-grey)",
                borderRadius: "5px",
                alignItems: "center",
                padding: "3% 5%",
                justifyContent: "space-between",
              }}
            >
              <div className="inner-location">
                <HiOutlineLocationMarker size={20} />
                {location?.name.length > 0 ? location.name : "Select Location"}
              </div>
              <Link to="/createSnafleItem/setLocation">Change</Link>
            </div>
          </Form.Field>
          <Form.Field>
            <label>Geofencing</label>
            <Checkbox toggle checked={geofence} onChange={handleCheckbox} />
            <p style={{ fontSize: "" }}>
              Only allow players to check in if they are within 250 metres of
              the location
            </p>
          </Form.Field>

          <Form.Field>
            <Button
              style={{
                width: "100%",
                backgroundColor: "var(--primary-color)",
                color: "white",
                borderRadius: 8,
              }}
              type="submit"
              onClick={() => setIsLoaded(false)}
            >
              Confirm Edit <span style={{ padding: "0 1%" }} />
              {!isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
            </Button>
            <Button
              style={{
                width: "100%",
                backgroundColor: "#D90D00",
                color: "white",
                borderRadius: 8,
                margin: "10% 0",
              }}
              type="button"
              onClick={() => {
                deleteItem();

                history.push(`/set/${currentItem.setId}`);
              }}
            >
              Delete Item
            </Button>
          </Form.Field>
        </div>
      </Form>
    </div>
  );
};
