import { Data } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Table } from "semantic-ui-react";
import { NavBar } from "..";
import authorization from "../../authorization";

const UserFeedBack = () => {
  const TableRowReport = (props: {
    sourceType: string;
    reportCategory: string;
    timestamp: string;
    userMessage: string;
    userSub: string;
    sourceId: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <Table.Row key={props.sourceId + props.userSub}>
        {/* <Table.Cell>{props.username}</Table.Cell> */}
        {/* <Table.Cell>{props.userSub}</Table.Cell> */}
        <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
        <Table.Cell>{props.reportCategory}</Table.Cell>
        <Table.Cell>{props.timestamp}</Table.Cell>
        <Table.Cell>{props.userMessage}</Table.Cell>
        <Table.Cell>
          <Link to={`/viewFeedback/${props.sourceId}_${props.userSub}`}>
            View Report
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowsReport = (props: { data: any }) => {
    return props.data?.map((x: any) => <TableRowReport {...x} />);
  };

  const ReportTableBugs = () => {
    const [data, setData] = useState<any>([]);
    const getReports = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports?type=BUG`;
      await fetch(url, {
        method: "GET",
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res?.items);
          setData(res?.items);
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
      getReports();
    }, []);

    // const [reverse, setReverse] = useState(false);

    // const sortData = (columnName: string, reverse: boolean) => {
    //   setReverse(!reverse);
    //   if (reverse) {
    //     data.sort((a: any, b: any) => a[columnName] - b[columnName]);
    //   } else {
    //     data.sort((a: any, b: any) => b[columnName] - a[columnName]);
    //   }
    // };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              <Table.HeaderCell>Source Type</Table.HeaderCell>
              <Table.HeaderCell>Report Category</Table.HeaderCell>
              <Table.HeaderCell>Report Timestamp</Table.HeaderCell>
              <Table.HeaderCell>User's Message</Table.HeaderCell>
              <Table.HeaderCell>View</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsReport data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const ReportTableFeedBack = () => {
    const [data, setData] = useState<any>([]);
    const getReports = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports?type=FEEDBACK`;
      await fetch(url, {
        method: "GET",
        headers: { ...(await authorization()) },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res?.items);
          setData(res?.items);
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
      getReports();
    }, []);

    // const [reverse, setReverse] = useState(false);

    // const sortData = (columnName: string, reverse: boolean) => {
    //   setReverse(!reverse);
    //   if (reverse) {
    //     data.sort((a: any, b: any) => a[columnName] - b[columnName]);
    //   } else {
    //     data.sort((a: any, b: any) => b[columnName] - a[columnName]);
    //   }
    // };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              <Table.HeaderCell>Source Type</Table.HeaderCell>
              <Table.HeaderCell>Report Category</Table.HeaderCell>
              <Table.HeaderCell>Report Timestamp</Table.HeaderCell>
              <Table.HeaderCell>User's Message</Table.HeaderCell>
              <Table.HeaderCell>View</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsReport data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const panes = [
    {
      menuItem: "Bugs",
      render: () => <ReportTableBugs />,
    },
    { menuItem: "Feedback", render: () => <ReportTableFeedBack /> },
  ];

  return (
    <>
      <NavBar />
      <div className="mod-main-conatiner">
        <Tab panes={panes} renderActiveOnly={true} />
      </div>
    </>
  );
};

export default UserFeedBack;
