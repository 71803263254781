import authorization from "../../authorization";

const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getActivities`;

interface Activity {
  id: string;
  name: string;
  assetName: string;
  iconType: string;
}

interface activityActions {
  FETCH_ACTIVITY_START: string;
  FETCH_ACTIVITY_SUCCESS: string;
  FETCH_ACTIVITY_FAIL: string;
}

const activityActionTypes: activityActions = {
  FETCH_ACTIVITY_START: "FETCH_ACTIVITY_START",
  FETCH_ACTIVITY_SUCCESS: "FETCH_ACTIVITY_SUCCESS",
  FETCH_ACTIVITY_FAIL: "FETCH_ACTIVITY_FAIL",
};

export const fetchActivityStart = () => ({
  type: activityActionTypes.FETCH_ACTIVITY_START,
});

export const fetchActivitySuccess = (data: Activity[]) => ({
  type: activityActionTypes.FETCH_ACTIVITY_SUCCESS,
  payload: data,
});

export const fetchActivityFail = (errorMessage: string) => ({
  type: activityActionTypes.FETCH_ACTIVITY_FAIL,
  payload: errorMessage,
});

const INITIAL_STATE = {
  data: [],
  isFetching: false,
  errorMessage: undefined,
};

export const fetchActivitiesAsync = () => {
  return async (dispatch: any) => {
    dispatch(fetchActivityStart());
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((response) => response.json())
      .then((jsonData) =>
        dispatch(fetchActivitySuccess(jsonData! as Activity[]))
      )
      .catch((err) => dispatch(fetchActivityFail(err)));
  };
};

const actReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case activityActionTypes.FETCH_ACTIVITY_START:
      return { ...state, isFetching: true };
    case activityActionTypes.FETCH_ACTIVITY_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case activityActionTypes.FETCH_ACTIVITY_FAIL:
      return { ...state, isFetching: false, errorMessage: action.payload };

    default:
      return state;
  }
};

export default actReducer;
