import React, { useEffect, useState } from "react";
import { NavBar } from "..";
import "./moderation.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { Button, Dropdown, Input, Select, Tab, Table } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { UploadLevel } from "../../uploadToS3";
import { Storage } from "aws-amplify";
import ViewReport from "../ViewReport/ViewReport";
import authorization from "../../authorization";
const Moderation = () => {
  const location = useLocation();
  const [modCount, setModCount] = useState(0);
  const [repCount, setRepCount] = useState(0);
  const [verCount, setVerCount] = useState(0);
  const [verData, setVerData] = useState<any>([]);
  const [repData, setRepData] = useState<any>([]);
  const [modData, setModData] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const options = [
    { key: "YOU", value: true, text: "Snafler Account Sets" },
    { key: "SNAFLER", value: false, text: "Your Sets" },
  ];

  const moderationStatusOptions = [
    { key: "ALL", value: "ALL", text: "ALL" },
    {
      key: "REJECTED",
      value: "REJECTED",
      text: "REJECTED",
    },
    {
      key: "REASON",
      value: "REASON",
      text: "REASON",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
      text: "ACCEPTED",
    },
    {
      key: "REVIEW",
      value: "REVIEW",
      text: "REVIEW",
    },
    {
      key: "PENDING",
      value: "PENDING",
      text: "PENDING",
    },
  ];

  const moderationSourceType = [
    { key: "ALL", value: "ALL", text: "ALL" },
    { key: "ITEM", value: "ITEM", text: "ITEM" },
    {
      key: "SET",
      value: "SET",
      text: "SET",
    },
    {
      key: "PROFILE_IMAGE",
      value: "PROFILE_IMAGE",
      text: "PROFILE_IMAGE",
    },
    {
      key: "PROFILE_THUMBNAIL",
      value: "PROFILE_THUMBNAIL",
      text: "PROFILE_THUMBNAIL",
    },
    {
      key: "PROFILE_COVER",
      value: "PROFILE_COVER",
      text: "PROFILE_COVER",
    },
    {
      key: "POST",
      value: "POST",
      text: "POST",
    },
  ];

  const contentSourceTypes = [
    { key: "ALL", value: "ALL", text: "ALL" },
    { key: "ITEM", value: "ITEM", text: "ITEM" },
    {
      key: "SET",
      value: "SET",
      text: "SET",
    },
    {
      key: "POST",
      value: "POST",
      text: "POST",
    },
    {
      key: "PROFILE",
      value: "PROFILE",
      text: "PROFILE",
    },
    {
      key: "COMMENT",
      value: "COMMENT",
      text: "COMMENT",
    },
  ];

  const moderationDateSort = [
    { key: "NEW", value: "NEWEST", text: "NEWEST-OLDEST" },
    { key: "OLDEST", value: "OLDEST", text: "OLDEST-NEWEST" },
    { key: "NORM", value: "NORM", text: "NORM" },
  ];
  // const getPresignedUrl = async (
  //   assetName: string,
  //   type: "post" | "set" | "item"
  // ) => {
  //   return await Storage.get(
  //     `${assetName}${type === "post" ? "_IMAGE_1" : ""}.jpg`,
  //     {
  //       identityId: "eu-west-1:f302d9d0-96fe-41d4-9a1d-58fcb88d47ed",
  //       level: UploadLevel.PROTECTED,
  //     }
  //   )
  //     .then((data: any) => {
  //       if (typeof data === "string") {
  //         setImageUrl(data);
  //       } else {
  //         console.log("Did not return a string: " + data);
  //       }
  //     })
  //     .catch((err: any) => console.log(err));
  // };

  const TableRowMod = (props: {
    username: string;
    userSub: string;
    sourceType: "post" | "set" | "item";
    mediaType: string;
    status: string;
    reason: string;
    moderationTimestamp: string;
    reviewedTimestamp: string;
    modifiedBy: string;
    sourceId: string;
  }) => {
    // console.log({ userSub: props.username });
    const [image, setImage] = useState<string>("");

    // useEffect(() => {
    //   getPresignedUrl(props.sourceId, props.sourceType);
    // }, []);

    return (
      <Table.Row key={props.userSub}>
        <Table.Cell>{props.username}</Table.Cell>
        <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
        <Table.Cell>{props.mediaType}</Table.Cell>
        <Table.Cell>{props.reason}</Table.Cell>
        <Table.Cell>{props.moderationTimestamp}</Table.Cell>
        <Table.Cell>{props.reviewedTimestamp}</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
        <Table.Cell>
          <Link
            to={`${location.pathname}/MOD_${props.userSub}_${props.sourceId}`}
          >
            View Report
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowReport = (props: {
    sourceType: string;
    reportCategory: string;
    timestamp: string;
    userMessage: string;
    userSub: string;
    status: string;
    sourceId: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <Table.Row key={props.sourceId + props.userSub}>
        {/* <Table.Cell>{props.username}</Table.Cell> */}
        <Table.Cell>{props.sourceType?.toUpperCase()}</Table.Cell>
        <Table.Cell>{props.reportCategory}</Table.Cell>
        <Table.Cell>{props.timestamp}</Table.Cell>
        <Table.Cell>{props.userMessage}</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
        <Table.Cell>
          <Link
            to={`${location.pathname}/REP_${props.userSub}_${props.sourceId}`}
          >
            View Report
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowVerification = (props: {
    username: string;
    timestamp: number;
    status: string;
    reason: string;
    fullName: string;
    documentType: string;
    alsoKnownAs: string;
    audience: string;
    requestCategory: string;
    requestId: string;
    userSub: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <Table.Row key={props.requestId}>
        {/* <Table.Cell>{props.username}</Table.Cell> */}
        <Table.Cell>{new Date(props.timestamp).toISOString()}</Table.Cell>
        <Table.Cell>{props.reason}</Table.Cell>
        <Table.Cell>{props.fullName}</Table.Cell>
        <Table.Cell>{props.documentType}</Table.Cell>
        <Table.Cell>{props.alsoKnownAs}</Table.Cell>
        <Table.Cell>{props.audience}</Table.Cell>
        <Table.Cell>{props.requestCategory}</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
        <Table.Cell>
          <Link
            to={`${location.pathname}/VER_${props.userSub}_${props.requestId}`}
          >
            View Report
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowsMod = (props: { data: any }) => {
    return props.data?.map((x: any) => <TableRowMod {...x} />);
  };

  const TableRowsReport = (props: { data: any }) => {
    return props.data?.map((x: any) => <TableRowReport {...x} />);
  };

  const TableRowsVerification = (props: { data: any }) => {
    return props.data?.map((x: any) => <TableRowVerification {...x} />);
  };

  const ModerationTable = (props: { data: any }) => {
    const [data, setData] = useState<any>(props.data);
    const [newData, setNewData] = useState(props.data);
    const [username, setUsername] = useState<string>();
    const [moderationStatus, setModerationStatus] = useState<string>("ALL");
    const [reverse, setReverse] = useState(false);
    const [sourceType, setSourceType] = useState("ALL");

    const [modTime, setModTime] = useState("NORM");
    const [reviewedTime, setRevTime] = useState("NORM");

    const handleUsernameSearch = () => {
      if (!username) {
        setData(newData);
        setSourceType("ALL");
        setModerationStatus("ALL");
        setUsername("");
        return;
      }
      if (
        sourceType !== "ALL" ||
        modTime !== "NORM" ||
        reviewedTime !== "NORM" ||
        moderationStatus !== "ALL"
      ) {
        setData(
          data.filter((x: any) =>
            x.username.toLowerCase().includes(username?.toLowerCase())
          )
        );
      } else
        setData(
          newData.filter((x: any) =>
            x.username?.toLowerCase().includes(username?.toLowerCase())
          )
        );
    };

    const handleUsernameSearchValue = (event: any, item: any) => {
      setUsername(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(newData);
        setSourceType("ALL");
        setModerationStatus("ALL");
        setUsername("");
        return;
      }
      setModerationStatus(item.value);
      if (
        username ||
        modTime !== "NORM" ||
        reviewedTime !== "NORM" ||
        sourceType !== "ALL"
      ) {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(newData.filter((x: any) => x.status === item.value));
      }
    };

    const handleSourceTypeChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(newData);
        setSourceType("ALL");
        setModerationStatus("ALL");
        setUsername("");
        return;
      }
      setSourceType(item.value);
      if (
        username ||
        modTime !== "NORM" ||
        reviewedTime !== "NORM" ||
        moderationStatus !== "ALL"
      ) {
        setData(
          data.filter((x: any) => x.sourceType === item.value.toLowerCase())
        );
      } else {
        setData(
          newData.filter((x: any) => x.sourceType === item.value.toLowerCase())
        );
      }
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (
        username ||
        modTime !== "NORM" ||
        reviewedTime !== "NORM" ||
        moderationStatus !== "ALL" ||
        sourceType !== "ALL"
      ) {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) =>
              a[attributeName] < b[attributeName]
                ? -1
                : a[attributeName] > b[attributeName]
                ? 1
                : 0
            )
          );
        } else {
          setData(
            data.sort((a: any, b: any) =>
              a[attributeName] > b[attributeName]
                ? -1
                : a[attributeName] < b[attributeName]
                ? 1
                : 0
            )
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            newData.sort((a: any, b: any) =>
              a[attributeName] < b[attributeName]
                ? -1
                : a[attributeName] > b[attributeName]
                ? 1
                : 0
            )
          );
        } else {
          setData(
            newData.sort((a: any, b: any) =>
              a[attributeName] > b[attributeName]
                ? -1
                : a[attributeName] < b[attributeName]
                ? 1
                : 0
            )
          );
        }
      }
    };

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("moderationTimestamp", item.value);
      setModTime(item.value);
    };

    const handleModerationReviewedTimeSort = (event: any, item: any) => {
      orderTime("reviewedTimestamp", item.value);
      setRevTime(item.value);
    };

    useEffect(() => {}, [
      sourceType,
      moderationStatus,
      modTime,
      reviewedTime,
      username,
    ]);

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Username{" "}
                <Input
                  placeholder="Filter Username"
                  action={{ icon: "search", onClick: handleUsernameSearch }}
                  onChange={handleUsernameSearchValue}
                />
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Source Type
                <div>
                  <Dropdown
                    options={moderationSourceType}
                    value={sourceType}
                    onChange={handleSourceTypeChange}
                    selection
                    fluid
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>Media Type</Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Moderation Reason
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Moderation Timestamp
                <div>
                  <Dropdown
                    options={moderationDateSort}
                    value={modTime}
                    onChange={handleModerationTimeSort}
                    selection
                    fluid
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Reviewed Timestamp
                <div>
                  <Dropdown
                    options={moderationDateSort}
                    value={reviewedTime}
                    onChange={handleModerationReviewedTimeSort}
                    selection
                    fluid
                  />
                </div>
              </Table.HeaderCell>

              <Table.HeaderCell onClick={() => {}}>
                Moderation Status
                <div>
                  <Dropdown
                    options={moderationStatusOptions}
                    value={moderationStatus}
                    onChange={handleStatusChange}
                    selection
                    fluid
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(newData);
                      setModTime("NORM");
                      setRevTime("NORM");
                      setSourceType("ALL");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{data ? <TableRowsMod data={data} /> : <></>}</Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const ReportTable = (props: { data: any }) => {
    const [data, setData] = useState<any>(props.data);
    const [newData, setNewData] = useState<any>(props.data);
    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [sourceType, setSourceType] = useState<any>("ALL");
    const [modTime, setModTime] = useState("NORM");
    const [reverse, setReverse] = useState(false);

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (
        modTime !== "NORM" ||
        moderationStatus !== "ALL" ||
        sourceType !== "ALL"
      ) {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) =>
              a[attributeName] < b[attributeName]
                ? -1
                : a[attributeName] > b[attributeName]
                ? 1
                : 0
            )
          );
        } else {
          setData(
            data.sort((a: any, b: any) =>
              a[attributeName] > b[attributeName]
                ? -1
                : a[attributeName] < b[attributeName]
                ? 1
                : 0
            )
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            newData.sort((a: any, b: any) =>
              a[attributeName] < b[attributeName]
                ? -1
                : a[attributeName] > b[attributeName]
                ? 1
                : 0
            )
          );
        } else {
          setData(
            newData.sort((a: any, b: any) =>
              a[attributeName] > b[attributeName]
                ? -1
                : a[attributeName] < b[attributeName]
                ? 1
                : 0
            )
          );
        }
      }
    };

    const handleSourceTypeChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(newData);
        setSourceType("ALL");
        setModerationStatus("ALL");

        return;
      }
      setSourceType(item.value);
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        setData(data.filter((x: any) => x.sourceType === item.value));
      } else {
        setData(newData.filter((x: any) => x.sourceType === item.value));
      }
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(newData);
        setSourceType("ALL");
        setModerationStatus("ALL");
        return;
      }

      setModerationStatus(item.value);
      if (modTime !== "NORM" || sourceType !== "ALL") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(newData.filter((x: any) => x.status === item.value));
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell onClick={() => {}}>
                Source Type
                <div>
                  <Dropdown
                    options={contentSourceTypes}
                    selection
                    fluid
                    value={sourceType}
                    onChange={handleSourceTypeChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Report Category
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Report Timestamp
                <div>
                  <Dropdown
                    options={moderationDateSort}
                    selection
                    fluid
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                User Message
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Request Status
                <div>
                  <Dropdown
                    options={moderationStatusOptions}
                    selection
                    fluid
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(newData);
                      setModTime("NORM");

                      setSourceType("ALL");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsReport data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };
  const VerificationTable = (props: { data: any }) => {
    const [data, setData] = useState<any>(props.data);
    const [newData, setNewData] = useState(props.data);
    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [reverse, setReverse] = useState(false);

    const [modTime, setModTime] = useState("NORM");

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) => a[attributeName] - b[attributeName])
          );
        } else {
          setData(
            data.sort((a: any, b: any) => b[attributeName] - a[attributeName])
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            newData.sort(
              (a: any, b: any) => a[attributeName] - b[attributeName]
            )
          );
        } else {
          setData(
            newData.sort(
              (a: any, b: any) => b[attributeName] - a[attributeName]
            )
          );
        }
      }
    };

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(newData);

        setModerationStatus("ALL");
        return;
      }

      setModerationStatus(item.value);
      if (modTime !== "NORM") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(newData.filter((x: any) => x.status === item.value));
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
              <Table.HeaderCell onClick={() => {}}>
                Request Timestamp
                <div>
                  <Dropdown
                    options={moderationDateSort}
                    selection
                    fluid
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                User Message
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>Fullname</Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Document Type
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Also Known As
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>Audience</Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Request Category
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => {}}>
                Request Status
                <div>
                  <Dropdown
                    options={moderationStatusOptions}
                    selection
                    fluid
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(newData);
                      setModTime("NORM");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsVerification data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const panes = [
    {
      menuItem: `Moderation Reports (${modCount || 0})`,
      render: () => <ModerationTable data={modData} />,
    },
    {
      menuItem: `Content Reports (${repCount || 0})`,
      render: () => <ReportTable data={repData} />,
    },
    {
      menuItem: `Verification Requests (${verCount || 0})`,
      render: () => <VerificationTable data={verData} />,
    },
  ];

  const getModerationData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getModerationData`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setModData(res.items);
        setModCount(res.items?.length);
      });
  };

  const getVerificationRequests = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=VERIFICATION`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setVerData(res.items);
        setVerCount(res.items?.length);
      });
  };
  const getReports = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getReports`;
    await fetch(url, {
      method: "GET",
      headers: { ...(await authorization()) },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.items);
        setRepData(res.items);
        setRepCount(res.items?.length);
      });
  };

  useEffect(() => {
    getVerificationRequests();
    getReports();
    getModerationData();
  }, []);

  Storage.configure({
    customPrefix: {
      public: "snaflerPublic/",
      protected: "snaflerProtected/",
      private: "snaflerPrivate/",
    },
  });

  useEffect(() => {}, []);
  return (
    <>
      <NavBar />
      <div className="mod-main-conatiner">
        <Tab panes={panes} renderActiveOnly={true} />
      </div>
    </>
  );
};

export default Moderation;
