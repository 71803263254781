import React, { useEffect, useRef, useState } from "react";
import { FiTrash, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Input, Item, Tab } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
//@ts-ignore
import LinesEllipsis from "react-lines-ellipsis";
import { useSpring, animated } from "react-spring";
import ScaleLoader from "react-spinners/ScaleLoader";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { deleteDiscoverData, loadDiscoverData } from "../../redux/discover";
import { Auth } from "aws-amplify";
import authorization from "../../authorization";
import { stringify } from "querystring";
const sections = [
  "Top Daily Sets",
  "Top Weekly Sets",
  "Trending Sets",
  "New Sets",
  "Timed Sets",
  "Ambassadors",
  "Pro Sets",
  "Pixel Sets",
];

const getWeekBegining = (date: number): Date => {
  let d = new Date(date);
  let day = d.getDay();
  let diff = d.getDate() - day + (day == 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

const getArrayOfWeekBeginings = (
  date: number
): { key: string; text: string; value: number }[] => {
  /**
   * get the next 25 week begining dates
   */
  let dates: { key: string; text: string; value: number }[] = [];

  let previousDates: { key: string; text: string; value: number }[] = [
    {
      key: new Date(date).toLocaleDateString("en-GB"),
      text:
        new Date(date).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }) + " (Now)",
      value: date,
    },
  ];

  for (let i = 1; i < 10; i++) {
    previousDates.push({
      key: new Date(
        previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB"),
      text: new Date(
        previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      value: previousDates[i - 1].value - 7 * 24 * 60 * 60 * 1000,
    });
  }

  dates = [
    ...previousDates.sort(
      (
        a: { key: string; text: string; value: number },
        b: { key: string; text: string; value: number }
      ) => a.value - b.value
    ),
  ];
  for (let i = 10; i < 35; i++) {
    dates.push({
      key: new Date(
        dates[i - 1].value + 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB"),
      text: new Date(
        dates[i - 1].value + 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      value: dates[i - 1].value + 7 * 24 * 60 * 60 * 1000,
    });
  }

  return dates.sort(
    (
      a: { key: string; text: string; value: number },
      b: { key: string; text: string; value: number }
    ) => a.value - b.value
  );
};

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const Discover = () => {
  const dispatch = useAppDispatch();

  //const localDiscoverData = useAppSelector((state) => state.discover.data);

  const [dailySets, setDailySets] = useState<{ id: string; title: string }[]>(
    []
  );
  const [timedSets, setTimedSets] = useState<{ id: string; title: string }[]>(
    []
  );

  const [pixelSets, setPixelSets] = useState<{ id: string; title: string }[]>(
    []
  );

  const [dailySetCount, SetDailySetCount] = useState(dailySets.length);
  const [weeklySets, setWeeklySets] = useState<{ id: string; title: string }[]>(
    []
  );

  const [proSetsDiscover, setProSetsDiscover] = useState<
    { id: string; title: string }[]
  >([]);

  const [trendingSets, setTrendingSets] = useState<
    { id: string; title: string }[]
  >([]);

  const [ambassadors, setAmbassadors] = useState<
    {
      id: string;
      title: string;
    }[]
  >([]);

  const [newSets, setNewSets] = useState<{ id: string; title: string }[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [value, setValue] = useState("");
  const [weekBegining, setWeekBegining] = useState(
    getArrayOfWeekBeginings(getWeekBegining(now.getTime()).getTime())
  );

  const [set, setData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [proSets, setProSets] = useState<any>([]);
  const [pixSets, setPixSets] = useState<any>([]);
  const [tSets, setTSets] = useState<any>([]);

  const getSets = async () => {
    // const user = "269e2220-2529-4fbd-a531-7ce5107f6330";

    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}getSets`, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonData) => {
        console.log(jsonData);

        setData(
          jsonData.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
          }))
        );
        const timedSets = jsonData.sets
          .filter((x: any) => x.timedSet)
          .map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
          }));
        setTSets(timedSets);
      });
  };

  const getProSets = async () => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}getSets?type=PRO`, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setProSets(
          res.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
            subscriptionType: data.subscriptionType,
          }))
        );
      });
  };

  const getPixelSets = async () => {
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getSets?action=PIXEL_CANVAS`,
      {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });

        setPixSets(
          res.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
            timedSet: data.timedSet,
            subscriptionType: data.subscriptionType,
          }))
        );
      });
  };

  const getUsers = async () => {
    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT2}getAllUsers?webapp=true`,
      {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        setUsers(data.users);
      });
  };

  const [availableDays, setAvailableDays] = useState([
    { key: "Monday", text: "Monday", value: "Monday" },
    { key: "Tuesday", text: "Tuesday", value: "Tuesday" },
    { key: "Wednesday", text: "Wednesday", value: "Wednesday" },
    { key: "Thursday", text: "Thursday", value: "Thursday" },
    { key: "Friday", text: "Friday", value: "Friday" },
    { key: "Saturday", text: "Saturday", value: "Saturday" },
    { key: "Sunday", text: "Sunday", value: "Sunday" },
  ]);

  // const handleDayChange = (event: any) => {
  //   let remainingDays = availableDays;
  //   let index = remainingDays.findIndex((v) => v.value === event.target.value);
  //   remainingDays.splice(index, 1);
  //   setAvailableDays(remainingDays);
  // };

  const handleOnDragEndDaily = (result: any) => {
    if (!result.destination) return;
    const items = dailySets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDailySets(items);
  };

  const handleOnDragEndWeekly = (result: any) => {
    if (!result.destination) return;
    const items = weeklySets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setWeeklySets(items);
  };
  const handleOnDragEndTrending = (result: any) => {
    if (!result.destination) return;
    const items = trendingSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTrendingSets(items);
  };

  const handleOnDragNewSets = (result: any) => {
    if (!result.destination) return;
    const items = newSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNewSets(items);
  };

  const handleOnDragTimedSets = (result: any) => {
    if (!result.destination) return;
    const items = timedSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTimedSets(items);
  };

  const handleOnDragAmbassadors = (result: any) => {
    if (!result.destination) return;
    const items = ambassadors;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAmbassadors(items);
  };

  const handleOnDragProSets = (result: any) => {
    if (!result.destination) return;
    const items = proSetsDiscover;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProSetsDiscover(items);
  };

  const handleOnDragPixelSets = (result: any) => {
    if (!result.destination) return;
    const items = pixelSets;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPixelSets(items);
  };

  const ItemSectionDaily = (props: {
    title: string;
    data: { id: string; title: string }[];
    cap: number;
    deleteMethod: (id: string) => void;
  }) => (
    <div>
      {isLoading ? (
        <div
          className="item-section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScaleLoader />
        </div>
      ) : (
        <div className="item-section">
          <h4>
            {props.title} - {dailySetCount}/{props.cap}
          </h4>
          <DragDropContext onDragEnd={handleOnDragEndDaily}>
            <Droppable droppableId="drag-n-drop-daily">
              {(provided) => (
                <div
                  className="drag-n-drop"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {props.data.map(
                    (item: { id: string; title: string }, index) => (
                      <div key={item.id}>
                        <p className="discover-daily-p">
                          {availableDays[index].value}
                        </p>

                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="list-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {item.id === "null" ? (
                                <LinesEllipsis
                                  className="txt"
                                  text={`Add a set to ${availableDays[index].value}`}
                                  maxLine="1"
                                  basedOn="letters"
                                  trimRight
                                  ellipsis="..."
                                />
                              ) : (
                                <>
                                  <LinesEllipsis
                                    className="txt"
                                    text={item.title}
                                    maxLine="1"
                                    basedOn="letters"
                                    trimRight
                                    ellipsis="..."
                                  />
                                  <div
                                    onClick={() => {
                                      console.log("DELETE");
                                      props.deleteMethod(item.id);
                                    }}
                                    className="delete"
                                    style={{ float: "right" }}
                                  >
                                    <FiTrash2 size={16} />
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );

  const ItemSection = (props: {
    title: string;
    data: { id: string; title: string }[];
    cap: number;
    deleteMethod: (id: string) => void;
    dragMethod: (result: any) => void;
  }) => (
    <div>
      {isLoading ? (
        <div
          className="item-section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ScaleLoader />
        </div>
      ) : (
        <div className="item-section">
          <h4>
            {props.title} - {props.data.length}/{props.cap}
          </h4>
          <DragDropContext onDragEnd={props.dragMethod}>
            <Droppable droppableId="drag-n-drop-weekly">
              {(provided) => (
                <div
                  className="drag-n-drop"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {props.data.map(
                    (item: { id: string; title: string }, index) => (
                      <div key={item.id} style={{ padding: "1%  0 " }}>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="list-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <LinesEllipsis
                                className="txt"
                                text={item.title}
                                maxLine="1"
                                basedOn="letters"
                                trimRight
                                ellipsis="..."
                              />
                              <div
                                onClick={() => {
                                  console.log("DELETE");
                                  props.deleteMethod(item.id);
                                }}
                                className="delete"
                                style={{ float: "right" }}
                              >
                                <FiTrash2 size={16} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );

  const AmbassadorCard = ({ item }: any) => {
    return (
      <div
        className={"set-card"}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${item.id}_profile_image.jpg`,
          width: "30%",
        }}
      >
        <h3>{item.username}</h3>
        <div className="button-group">
          <button
            className="button-discover"
            onClick={() => {
              addToAmbassadors({ id: item.id, title: item.username });
            }}
            disabled={
              ambassadors.findIndex((v) => v.id === item.id) >= 0 ||
              ambassadors.length === 5 ||
              disableBtn
            }
          >
            Add Ambassador
          </button>
        </div>
      </div>
    );
  };

  const [currentDate, setCurrentDate] = useState(
    getWeekBegining(now.getTime()).toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );

  const [currentId, setCurrentId] = useState<any>();

  useEffect(() => {
    getSets();
    getUsers();
    getProSets();
    getPixelSets();
    // setCurrentDate(
    //   localDiscoverData?.startDate ||
    //     getWeekBegining(now.getTime()).toLocaleDateString("en-GB", {
    //       year: "numeric",
    //       month: "long",
    //       day: "numeric",
    //     })
    // );
    // setDailySets(localDiscoverData?.topDailySets || []);
    // setWeeklySets(localDiscoverData?.topWeeklySets || []);
    // setTrendingSets(localDiscoverData?.trendingSnafleSets || []);
  }, []);

  const getDiscoverDataByDate = async (date: string) => {
    date = date.slice(0, 10);
    console.log(date);

    setIsLoading(true);
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getDiscoverData?date=${date}`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => {
        return data?.json();
      })
      .then((data) => {
        console.log(data);
        setCurrentId(data?.id);
        let topDaily = [];
        for (let i in data.topDaily) {
          topDaily.push({
            id: data.topDaily[i]?.id,
            title: data.topDaily[i]?.title,
          });
        }
        setDailySets(topDaily);
        SetDailySetCount(topDaily.length);
        let topWeekly = [];
        for (let i in data.topWeekly) {
          topWeekly.push({
            id: data.topWeekly[i]?.id,
            title: data.topWeekly[i]?.title,
          });
        }
        setWeeklySets(topWeekly);
        let topTrending = [];
        for (let i in data.topTrending) {
          topTrending.push({
            id: data.topTrending[i]?.id,
            title: data.topTrending[i]?.title,
          });
        }
        setTrendingSets(topTrending);
        let topNewSets = [];
        for (let i in data.topNewSets) {
          topNewSets.push({
            id: data.topNewSets[i]?.id,
            title: data.topNewSets[i]?.title,
          });
        }
        setNewSets(topNewSets);
        let ams = [];
        for (let i in data.ambassadors) {
          ams.push({
            id: data.ambassadors[i]?.id,
            title: data.ambassadors[i]?.username,
          });
        }
        let ts = [];
        for (let i in data?.topTimedSets) {
          ts.push({
            id: data?.topTimedSets[i]?.id,
            title: data?.topTimedSets[i]?.title,
          });
        }
        setTimedSets(ts);
        setAmbassadors(ams);
        let pS = [];
        for (let i in data.proSets) {
          pS.push({
            id: data.proSets[i]?.id,
            title: data.proSets[i]?.title,
          });
        }
        setProSetsDiscover(pS);
        let pixS = [];
        for (let i in data.pixelSets) {
          pixS.push({
            id: data.pixelSets[i]?.id,
            title: data.pixelSets[i]?.title,
          });
        }
        setPixelSets(pixS);
        setIsLoading(false);
      })
      .catch((err) => {
        setCurrentId(undefined);
        SetDailySetCount(0);
        setDailySets([]);
        setWeeklySets([]);
        setTrendingSets([]);
        setNewSets([]);
        setTimedSets([]);
        setAmbassadors([]);
        setProSetsDiscover([]);
        setPixSets([]);
        setIsLoading(false);
        console.log(err);
      });
  };

  const [disableBtn, setDisableBtn] = useState(
    new Date().getTime() - 7 * 24 * 60 * 60 * 1000 >
      new Date(
        currentDate.substring(3, 2) +
          "/" +
          currentDate.substring(0, 2) +
          "/" +
          currentDate.substring(6, 4)
      ).getTime()
  );

  useEffect(() => {
    getDiscoverDataByDate(
      new Date(Date.parse(currentDate)).toLocaleDateString("en-GB")
    );
    setErrorMsg("");
    setSuccessMsg("");

    setDisableBtn(
      new Date().getTime() - 7 * 24 * 60 * 60 * 1000 >
        new Date(
          currentDate.substring(3, 2) +
            "/" +
            currentDate.substring(0, 2) +
            "/" +
            currentDate.substring(6, 4)
        ).getTime()
    );
    //dispatch(deleteDiscoverData());
  }, [currentDate]);

  const addToDaily = (data: { id: string; title: string }) => {
    if (dailySets.findIndex((v) => v.id === data.id) === -1) {
      let index = dailySets.findIndex((v) => v.id === "null");
      if (index !== -1) {
        let arr = dailySets;
        arr[index] = data;
        setDailySets(arr);
      } else {
        setDailySets([...dailySets, data]);
      }

      SetDailySetCount(dailySetCount + 1);
    }
  };

  const removeFromDaily = (id: string) => {
    let arr = dailySets;
    let index = arr.findIndex((v) => v.id === id);
    arr[index] = { id: "null", title: "" };
    setDailySets([...arr]);
    SetDailySetCount(dailySetCount - 1);
  };

  const addToWeekly = (data: { id: string; title: string }) => {
    if (weeklySets.findIndex((v) => v.id === data.id) === -1)
      setWeeklySets([...weeklySets, data]);
  };

  const removeFromWeekly = (id: string) => {
    let arr = weeklySets;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setWeeklySets([...arr]);
  };

  const addToTrending = (data: { id: string; title: string }) => {
    if (trendingSets.findIndex((v) => v.id === data.id) === -1)
      setTrendingSets([...trendingSets, data]);
  };

  const removeFromTrending = (id: string) => {
    let arr = trendingSets;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setTrendingSets([...arr]);
  };

  const addToNewSets = (data: { id: string; title: string }) => {
    if (newSets.findIndex((v) => v.id === data.id) === -1)
      setNewSets([...newSets, data]);
  };

  const addToTimedSets = (data: { id: string; title: string }) => {
    if (timedSets.findIndex((v) => v.id === data.id) === -1)
      setTimedSets([...timedSets, data]);
  };
  const removeFromTimedSets = (id: string) => {
    let arr = timedSets;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setTimedSets([...arr]);
  };

  const addToAmbassadors = (data: { id: string; title: string }) => {
    if (ambassadors.findIndex((v) => v.id === data.id) === -1)
      setAmbassadors([...ambassadors, data]);
  };
  const removeFromAmbassadors = (id: string) => {
    let arr = ambassadors;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setAmbassadors([...arr]);
  };

  const addToProSets = (data: { id: string; title: string }) => {
    if (proSetsDiscover.findIndex((v) => v.id === data.id) === -1)
      setProSetsDiscover([...proSetsDiscover, data]);
  };
  const removeFromProSets = (id: string) => {
    let arr = proSetsDiscover;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setProSetsDiscover([...arr]);
  };

  const addToPixelSets = (data: { id: string; title: string }) => {
    if (pixelSets.findIndex((v) => v.id === data.id) === -1)
      setPixelSets([...pixelSets, data]);
  };
  const removeFromPixelSets = (id: string) => {
    let arr = pixelSets;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setPixelSets([...arr]);
  };

  const removeFromNewSets = (id: string) => {
    let arr = newSets;
    let index = arr.findIndex((v) => v.id === id);
    arr.splice(index, 1);
    setNewSets([...arr]);
  };

  // console.log(new Date(Date.parse(currentDate)).toLocaleDateString("en-GB"));

  const submitNewData = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}createDiscoverData`;
    if (dailySets.length < 7) {
      setErrorMsg("You need 7 daily sets");
      return;
    }
    if (weeklySets.length < 10) {
      setErrorMsg("You need 10 weekly sets");
      return;
    }
    if (trendingSets.length < 10) {
      setErrorMsg("You need 10 trending sets");
      return;
    }

    await fetch(url, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: currentId,
        startDate: new Date(Date.parse(currentDate)).toLocaleDateString(
          "en-GB"
        ),
        topDailySets: dailySets.map((item) => item.id),
        topWeeklySets: weeklySets.map((item) => item.id),
        trendingSnafleSets: trendingSets.map((item) => item.id),
        topNewSets: newSets.map((item) => item.id),
        topTimedSets: timedSets.map((item) => item.id),
        ambassadors: ambassadors.map((item) => ({
          id: item.id,
          username: item.title,
        })),
        proSets: proSetsDiscover.map((item) => item.id),
        pixelSets: pixelSets.map((item) => item.id),
      }),
    }).then((data) => {
      console.log({ status: data.status });
      if (data.status === 204) {
        setSuccessMsg("Submitted");
        setErrorMsg("");
      }
      getDiscoverDataByDate(
        new Date(Date.parse(currentDate)).toLocaleDateString("en-GB")
      );
    });
  };

  const Set = ({ item }: any) => {
    const history = useHistory();
    // console.log({ timedSet: item });
    return (
      <div
        className="set-card"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${item.id}.jpg`,
        }}
      >
        <h2>{item.title}</h2>

        <div
          style={{
            maxHeight: "0",
            overflow: "hidden",
            textOverflow: "ellipsis",
            margin: 0,
          }}
        >
          <p style={{ padding: 0, margin: "1% 0" }}>
            {item.timedSet ? "timedSet" : ""}
          </p>
          {/* <p style={{ padding: 0, margin: "1% 0" }}>
            Subscribers: {item.subscribers}
          </p> */}
          {/* <p style={{ padding: 0, margin: "1% 0" }}>Item(s): {item.numItems}</p> */}
          {/* <p style={{ padding: 0, margin: "1% 0" }}>
            Activity: {item.activityType}
          </p> */}
        </div>
        <div className="button-group">
          <button
            className="button-discover"
            onClick={() => {
              addToDaily({ id: item.id, title: item.title });
            }}
            disabled={
              dailySets.findIndex((v) => v.id === item.id) >= 0 ||
              dailySetCount === 7 ||
              disableBtn
            }
          >
            Daily Sets
          </button>
          <button
            className="button-discover"
            onClick={() => {
              addToWeekly({ id: item.id, title: item.title });
            }}
            disabled={
              weeklySets.findIndex((v) => v.id === item.id) >= 0 ||
              weeklySets.length === 10 ||
              disableBtn
            }
          >
            Top Weekly
          </button>
          <button
            className="button-discover"
            onClick={() => {
              addToTrending({ id: item.id, title: item.title });
            }}
            disabled={
              trendingSets.findIndex((v) => v.id === item.id) >= 0 ||
              trendingSets.length === 10 ||
              disableBtn
            }
          >
            Trending
          </button>
          <button
            className="button-discover"
            onClick={() => {
              addToNewSets({ id: item.id, title: item.title });
            }}
            disabled={
              newSets.findIndex((v) => v.id === item.id) >= 0 ||
              newSets.length >= 10 ||
              disableBtn
            }
          >
            New Set
          </button>
        </div>
      </div>
    );
  };

  const SetPro = ({ item }: any) => {
    return (
      <div
        className="set-card"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${item.id}.jpg`,
        }}
      >
        <h2>{item.title}</h2>
        <div className="button-group">
          <button
            className="button-discover"
            onClick={() => {
              addToProSets({ id: item.id, title: item.title });
            }}
            disabled={
              proSetsDiscover.findIndex((v) => v.id === item.id) >= 0 ||
              proSetsDiscover?.length === 20 ||
              disableBtn
            }
          >
            Add to Pro Sets
          </button>
        </div>
      </div>
    );
  };

  const SetPixel = ({ item }: any) => {
    return (
      <div
        className="set-card"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${item.id}.jpg`,
        }}
      >
        <h2>{item.title}</h2>
        <div className="button-group">
          <button
            className="button-discover"
            onClick={() => {
              addToPixelSets({ id: item.id, title: item.title });
            }}
            disabled={
              pixelSets.findIndex((v) => v.id === item.id) >= 0 ||
              pixelSets?.length === 10 ||
              disableBtn
            }
          >
            Add to Pixel Sets
          </button>
        </div>
      </div>
    );
  };

  const SetTimed = ({ item }: any) => {
    return (
      <div
        className="set-card"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(https://snafleruserdatastore120725-qa.s3.eu-west-1.amazonaws.com/snaflerPublic/${item.id}.jpg`,
        }}
      >
        <h2>{item.title}</h2>
        <div className="button-group">
          <button
            className="button-discover"
            onClick={() => {
              addToTimedSets({ id: item.id, title: item.title });
            }}
            disabled={
              timedSets.findIndex((v) => v.id === item.id) >= 0 || disableBtn
            }
          >
            Add to Timed Sets
          </button>
        </div>
      </div>
    );
  };

  const handleChange = (event: any) => {
    console.log({ result: event.target.outerText });
    setCurrentDate(event.target.outerText);
  };

  const panes = [
    {
      menuItem: `Sets`,
      render: () => <DiscoverSets />,
    },
    {
      menuItem: `Ambassadors`,
      render: () => <DiscoverAmbassadors />,
      // render: () => <ReportTable data={repData} />,
    },
    {
      menuItem: `Pro Sets`,
      render: () => <ProSets />,
      // render: () => <ReportTable data={repData} />,
    },
    {
      menuItem: `Timed Sets`,
      render: () => <TimedSets />,
      // render: () => <ReportTable data={repData} />,
    },

    {
      menuItem: `Pixel Sets`,
      render: () => <PixelSets />,
      // render: () => <ReportTable data={repData} />,
    },
  ];

  const DiscoverAmbassadors = () => (
    <Tab.Pane>
      <div className="sets-container">
        {users.map((item: any) => (
          <AmbassadorCard item={item} />
        ))}
      </div>
    </Tab.Pane>
  );

  const ProSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        {proSets.map((item: any) => (
          <SetPro key={item.id} item={item} />
        ))}
      </div>
    </Tab.Pane>
  );

  const PixelSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        {pixSets.map((item: any) => (
          <SetPixel key={item.id} item={item} />
        ))}
      </div>
    </Tab.Pane>
  );

  const TimedSets = () => (
    <Tab.Pane>
      <div className="sets-container">
        {tSets.map((item: any) => (
          <SetTimed key={item.id} item={item} />
        ))}
      </div>
    </Tab.Pane>
  );

  const DiscoverSets = () => (
    <Tab.Pane>
      {/* <Input
        id="AutocompleteId"
        placeholder="Search Sets"
        style={{ margin: "0%", width: "100%" }}
        value={value}
        onChange={(val) => {
          setValue(val.target.value);
        }}
      /> */}

      <div className="sets-container">
        {set.map((item: any) => (
          <Set key={item.id} item={item} />
        ))}
      </div>
    </Tab.Pane>
  );

  return (
    <div>
      <div className="nav-bar-top">
        {/**
         * Header
         *
         */}
        <h3>Set Up Discover</h3>
      </div>
      <div className="primary-container">
        {/**
         * main flex
         */}
        <div className="left-column-free">
          <Tab panes={panes} renderActiveOnly={true} />
        </div>
        <div className="right-column-fixed">
          <h2 style={{ textAlign: "center" }}>Your Selection</h2>
          <div className="discover-week-picker">
            <h5>Week Beginning</h5>
            <Dropdown
              onChange={handleChange}
              options={weekBegining}
              placeholder={currentDate}
              selection
              value={currentDate}
            />
          </div>

          <ItemSectionDaily
            title={sections[0]}
            data={dailySets}
            cap={7}
            deleteMethod={removeFromDaily}
          />
          <ItemSection
            title={sections[1]}
            data={weeklySets}
            cap={10}
            deleteMethod={removeFromWeekly}
            dragMethod={handleOnDragEndWeekly}
          />
          <ItemSection
            title={sections[2]}
            data={trendingSets}
            cap={10}
            deleteMethod={removeFromTrending}
            dragMethod={handleOnDragEndTrending}
          />
          <ItemSection
            title={sections[3]}
            data={newSets}
            cap={10}
            deleteMethod={removeFromNewSets}
            dragMethod={handleOnDragNewSets}
          />
          <ItemSection
            title={sections[4]}
            data={timedSets}
            cap={5}
            deleteMethod={removeFromTimedSets}
            dragMethod={handleOnDragTimedSets}
          />
          <ItemSection
            title={sections[5]}
            data={ambassadors}
            cap={5}
            deleteMethod={removeFromAmbassadors}
            dragMethod={handleOnDragAmbassadors}
          />
          <ItemSection
            title={sections[6]}
            data={proSetsDiscover}
            cap={20}
            deleteMethod={removeFromProSets}
            dragMethod={handleOnDragProSets}
          />
          <ItemSection
            title={sections[7]}
            data={pixelSets}
            cap={10}
            deleteMethod={removeFromPixelSets}
            dragMethod={handleOnDragPixelSets}
          />
          <p style={{ textAlign: "center", color: "red" }}>{errorMsg}</p>
          <p style={{ textAlign: "center", color: "green" }}>{successMsg}</p>
          <button
            className="button-discover"
            style={{
              justifySelf: "flex-end",
              padding: "4%",
            }}
            onClick={() => {
              submitNewData();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
