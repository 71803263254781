import React, { useEffect, useState } from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Button, Checkbox, Form } from "semantic-ui-react";
import { InputExampleInput } from "../Inputs";
import { NavBarTopCustom } from "../Navbar";
import styled from "styled-components";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Auth } from "aws-amplify";
import { stringify } from "querystring";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteCurrentSet,
  fetchCurrentSetData,
  loadCurrentSet,
} from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import { deleteLocation } from "../../redux/location";
import { shallowEqual } from "react-redux";
import Cliploader from "react-spinners/ClipLoader";
import authorization from "../../authorization";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export const SetForm = (props: any): JSX.Element => {
  const currentSet = useAppSelector(
    (state) => state.currentSet.data,
    shallowEqual
  );
  console.log(currentSet);

  const [setTitle, setSetTitle] = useState<string>("");
  const [setDesc, setSetDesc] = useState<string>("");
  const [cat, setCat] = useState([{ id: "", name: "" }]);
  const [act, setAct] = useState([{ id: "", name: "" }]);
  const [setActivityType, setSetActivityType] = useState("");
  const [setCategory, setSetCategory] = useState("");
  const [setPrivate, setSetPrivate] = useState("");
  const [timedSet, setTimedSet] = useState(false);
  const [userSub, setUserSub] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const location = useAppSelector((state) => state.location.data);
  const categories = useAppSelector((state) => state.category.data);
  const activities = useAppSelector((state) => state.activity.data);
  const [startedDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [startDateTimeHours, setStartDateTimeHours] = useState(0);
  const [startDateTimeMinutes, setStartDateTimeMinutes] = useState(0);
  const [endDateTimeHours, setEndDateTimeHours] = useState(0);
  const [endDateTimeMinutes, setEndDateTimeMinutes] = useState(0);

  const [snafleAction, setSnafleAction] = useState(false);

  const Small = styled.p`
    font-size: 0.8em;
    color: var(--grey-color);
  `;

  const LocationDiv = styled.div`
    display: flex;
    flex-flow: row;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    align-items: center;
    padding: 3% 5%;
    justify-content: space-between;
  `;

  const createSetUrl = `${process.env.REACT_APP_AWS_ENDPOINT1}createSet`;

  const getUserCred = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      console.log("user is signed in");
      //setUserAuthenticated(true);
      Auth.currentSession().then((res) => {
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        setUserSub(res.getIdToken().payload.sub);
        //console.log(accessToken.payload);
        //You can print them to see the full objects
        //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
        //console.log(`myJwt: ${jwt}`);
        //this.accessToken = JSON.stringify(accessToken);
        //this.setState({ token: `${JSON.stringify(accessToken)}` });
      });
      return true;
    } catch (err) {
      console.log("user is not signed in");
      return false;
    }
  };
  type Category = { id: string; name: string };

  const handleCheckbox = (event: any, data: any) => {
    setTimedSet(data.checked);
    dispatch(loadCurrentSet({ ...currentSet, timedSet: data.checked }));
  };

  const handleCalendar = (event: any) => {
    console.log({ start: event[0], end: event[1] });
    setStartDate(event[0]);
    setEndDate(event[1]);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: event[0],
        endDate: event[1],
      })
    );
  };

  useEffect(() => {
    getUserCred();
    const compareStrings = (a: string, b: string) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    };
    let categoriesSorted = categories;
    categoriesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });

    let activitiesSorted = activities;
    activitiesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });

    setCat(categoriesSorted);

    setAct(activitiesSorted);

    setSetTitle(currentSet?.title);
    setSetDesc(currentSet?.description);
    setSetActivityType(currentSet?.activityType || "Climb");
    setSetCategory(currentSet?.categoryId || "Action Figures");
    setTimedSet(currentSet?.timedSet || false);
    setStartDate(currentSet?.startDate || new Date());
    setEndDate(currentSet?.endDate || new Date());
    setStartDateTimeHours(currentSet?.startDateTimeHours || 0);
    setStartDateTimeMinutes(currentSet?.startDateTimeMinutes || 0);
    setEndDateTimeHours(currentSet?.endDateTimeHours || 0);
    setEndDateTimeMinutes(currentSet?.endDateTimeMinutes || 0);
    setSnafleAction(
      currentSet?.snafleAction === "PIXEL_CANVAS" ||
        currentSet?.snafleAction === true
    );
  }, []);

  const history = useHistory();

  const createSet = async (event: any) => {
    event.preventDefault();
    let data = {
      title: setTitle,
      description: setDesc,
      location: location || {
        name: "Global",
        type: "GLOBAL",
        formatted_address: "GLOBAL",
      },
      activityType: setActivityType,
      categoryId: setCategory,
      status: "DRAFT",
      createdBy: userSub,
      timedSet,
      startDate: startedDate?.toISOString(),
      endDate: endDate?.toISOString(),
      snafleAction: snafleAction === true ? "PIXEL_CANVAS" : undefined,
    };
    console.log(data);

    await fetch(createSetUrl, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        history.push(`/set/${data.setId}`);
      });

    dispatch(deleteLocation());
    dispatch(deleteCurrentSet());
    dispatch(deleteCurrentItem());
  };

  const generateHours = () => {
    let details: { text: string; value: number; key: number }[] = [];
    for (let i = 0; i < 24; i++) {
      details.push({ text: `${i}`, value: i, key: i });
    }
    return details;
  };

  const generateMins = () => {
    let details: { text: string; value: number; key: number }[] = [];
    for (let i = 0; i < 60; i++) {
      details.push({ text: `${i}`, value: i, key: i });
    }
    return details;
  };

  const handleHoursStart = (event: any, data: any) => {
    setStartDate(
      new Date(startedDate.setHours(data.value, startDateTimeMinutes, 0))
    );

    setStartDateTimeHours(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: new Date(
          startedDate.setHours(data.value, startDateTimeMinutes, 0)
        ),
        startDateTimeHours: data.value,
      })
    );
  };
  const handleMinutesStart = (event: any, data: any) => {
    setStartDate(
      new Date(startedDate.setHours(startDateTimeHours, data.value, 0))
    );
    setStartDateTimeMinutes(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: new Date(
          startedDate.setHours(startDateTimeHours, data.value, 0)
        ),
        startDateTimeMinutes: data.value,
      })
    );
  };

  const handleHoursEnd = (event: any, data: any) => {
    setEndDate(new Date(endDate.setHours(data.value, endDateTimeMinutes, 0)));
    setEndDateTimeHours(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        endDate: new Date(endDate.setHours(data.value, endDateTimeMinutes, 0)),
        endDateTimeHours: data.value,
      })
    );
  };
  const handleMinutesEnd = (event: any, data: any) => {
    setEndDate(new Date(endDate.setHours(endDateTimeHours, data.value, 0)));
    setEndDateTimeMinutes(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        endDate: new Date(endDate.setHours(endDateTimeHours, data.value, 0)),
        endDateTimeMinutes: data.value,
      })
    );
  };

  const handleSnafleAction = (event: any, data: any) => {
    setSnafleAction(data.checked);
    dispatch(loadCurrentSet({ ...currentSet, snafleAction: data.checked }));
  };

  const dispatch = useAppDispatch();

  return (
    <div>
      <NavBarTopCustom
        buttonLeft="Discard"
        title="Create Snafle Set"
        buttonRight=""
      />
      {
        <Form className="custom-form" onSubmit={createSet}>
          <Form.Field required>
            <label>Title</label>
            <Input
              required={true}
              placeholder="Title your Snafle Set"
              value={setTitle}
              onChange={(val) => {
                setSetTitle(val.target.value);
                dispatch(
                  loadCurrentSet({ ...currentSet, title: val.target.value })
                );
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Description</label>
            <textarea
              required={true}
              placeholder="Describe what your Snafle Set Involves? Include rules, instructions or tips!"
              id="SetDesc"
              className=""
              name="SetDesc"
              value={setDesc}
              onChange={(val) => {
                setSetDesc(val.target.value);
                dispatch(
                  loadCurrentSet({
                    ...currentSet,
                    description: val.target.value,
                  })
                );
              }}
              style={{ resize: "none" }}
            ></textarea>
          </Form.Field>
          <p style={{ fontSize: "0.8em", color: "var(--grey-color)" }}>
            Add #tags to help users discover you set
          </p>

          <Form.Field className="custom-form-group" required>
            <label>Private Snafle</label>
            <Checkbox
              required={true}
              toggle
              type="checkbox"
              id="SetPrivate"
              name="SetPrivate"
              className=""
              onChange={() => {}}
            />
          </Form.Field>
          <p style={{ fontSize: "0.8em", color: "var(--grey-color)" }}>
            Make your set private to keep it only visible to you
          </p>
          <Form.Field required>
            <label>What activity type does it involve?</label>
            <select
              value={setActivityType}
              required={true}
              placeholder={"Select an Activity Type"}
              onChange={(val) => {
                console.log(val.target.value);
                setSetActivityType(val.target.value);
                dispatch(
                  loadCurrentSet({
                    ...currentSet,
                    activityType: val.target.value,
                  })
                );
              }}
            >
              {
                <option selected disabled>
                  Select and Activity Type
                </option>
              }
              {act.map((item, key) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>Add Location?</label>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                border: "1px solid var(--light-grey)",
                borderRadius: "5px",
                alignItems: "center",
                padding: "3% 5%",
                justifyContent: "space-between",
              }}
            >
              <div className="inner-location">
                <HiOutlineLocationMarker size={20} />
                {location?.name.length > 0 ? location.name : "Select Location"}
              </div>
              <Link to="/createSnafleSet/setLocation">Change</Link>
            </div>
          </Form.Field>
          <Form.Field required>
            <label>Set Category</label>
            <select
              value={setCategory}
              required={true}
              onChange={(val) => {
                setSetCategory(val.target.value);
                dispatch(
                  loadCurrentSet({
                    ...currentSet,
                    categoryId: val.target.value,
                  })
                );
              }}
            >
              <option selected disabled>
                Select a Category
              </option>
              {cat.map((item: { id: string; name: string }) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>Timed Set</label>
            <Checkbox toggle checked={timedSet} onChange={handleCheckbox} />
            <div style={{ margin: "2% 0" }}>
              {timedSet ? (
                <div>
                  <Calendar
                    selectRange={true}
                    onChange={handleCalendar}
                    value={[startedDate, endDate]}
                  />
                  <p>Start Time</p>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <p>Hours</p>
                      <Dropdown
                        placeholder="Hours"
                        selection
                        options={generateHours()}
                        onChange={handleHoursStart}
                        value={startDateTimeHours}
                      />
                    </div>
                    <div style={{ width: "45%" }}>
                      <p>Minutes</p>
                      <Dropdown
                        placeholder="Minutes"
                        selection
                        options={generateMins()}
                        onChange={handleMinutesStart}
                        value={startDateTimeMinutes}
                      />
                    </div>
                  </div>
                  <p>End Time</p>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <p>Hours</p>
                      <Dropdown
                        placeholder="Hours"
                        selection
                        options={generateHours()}
                        onChange={handleHoursEnd}
                        value={endDateTimeHours}
                      />
                    </div>
                    <div style={{ width: "45%" }}>
                      <p>Minutes</p>
                      <Dropdown
                        placeholder="Minutes"
                        selection
                        options={generateMins()}
                        onChange={handleMinutesEnd}
                        value={endDateTimeMinutes}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Form.Field>
          <Form.Field>
            <label>Pixel Set</label>
            <Checkbox
              toggle
              checked={snafleAction}
              onChange={handleSnafleAction}
            />
          </Form.Field>
          <Form.Field>
            <Button
              style={{
                width: "100%",
                backgroundColor: "var(--primary-color)",
                color: "white",
                borderRadius: 8,
              }}
              type="submit"
              onClick={() => setIsLoaded(false)}
            >
              Create Set <span style={{ padding: "0 1%" }} />
              {!isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
            </Button>
          </Form.Field>
        </Form>
      }
    </div>
  );
};
