import React, { useEffect, useRef, useState } from "react";
import { FiCamera } from "react-icons/fi";
import { useHistory } from "react-router-dom";
//@ts-ignore
import imageToBase64 from "image-to-base64/browser";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "react-tabs/style/react-tabs.css";
import { Input } from "semantic-ui-react";
import { NavBarTopCustom } from "..";
import { useAppDispatch } from "../../hooks";
import { loadNewImage } from "../../redux/image";
import { loadNewBase64 } from "../../redux/base64";

export interface Data {
  id?: string;
  author: string;
  img?: string;
  downloadLink: string;
  url?: string;
}

const unsplashKey = "yDgm1VH48dp0PfFTIOgCBacLzqTz9yJl1GF-560t2dY";

export const ImageChooser = (props: any) => {
  const [img, setImgs] = useState([]! as Data[]);
  const [value, setValue] = useState("");
  const [file, setFile] = useState("");
  const inputFile = useRef<HTMLInputElement>(null);

  const openFileExp = () => {
    if (inputFile.current !== null) {
      inputFile.current.click();
    }
  };

  const dispatch = useAppDispatch();
  const history = useHistory();
  const getPhotos = async () => {
    const data = await fetch(`https://api.unsplash.com/photos?page=1`, {
      headers: {
        Authorization: "Client-ID" + " " + unsplashKey,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        let arr = [];
        for (let i in data) {
          arr.push({
            id: data[i].id,
            author: data[i].user.username,
            img: data[i].urls.regular,
            downloadLink: data[i].links.download_location,
          });
        }
        return arr;
      });
    setImgs(data);
  };
  useEffect(() => {
    getPhotos();
  }, [ImageChooser]);

  const getUnsplashImages = async (): Promise<void> => {
    let input = value;

    if (value.length === 0) {
      setImgs([]! as Data[]);
      getPhotos();
    } else {
      const url = `https://api.unsplash.com/search/photos?query=${input}`;
      const data = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Client-ID" + " " + unsplashKey,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          let arr = [];
          for (let i in data.results) {
            arr.push({
              id: data.results[i].id,
              author: data.results[i].user.username,
              img: data.results[i].urls.regular,
              downloadLink: data.results[i].links.download_location,
            });
          }
          return arr;
        });
      setImgs(data);
    }
  };

  const RenderItem = (item: any) => {
    return (
      <div
        className="unsplashImg"
        style={{ backgroundImage: `url(${item.item.img})` }}
        onClick={() => {
          dispatch(
            loadNewImage({
              author: item.item.author as string,
              url: item.item.img as string,
              downloadLink: item.item.downloadLink as string,
            })
          );
          history.goBack();
        }}
      >
        <p
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            padding: "0 10%",
            textAlign: "center",
            color: "white",
          }}
        >
          {item.item.author}
        </p>
      </div>
    );
  };

  const handleChange = (event: any) => {
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <>
      <NavBarTopCustom buttonLeft="" title={`Add Image`} buttonRight="" />

      <Tabs className="tabs">
        <TabList style={{ padding: "0% 5%", margin: 0 }}>
          <Tab style={{ padding: "2% 5%" }}>Unsplash</Tab>
          <Tab style={{ padding: "2% 5%" }}>Upload Image</Tab>
        </TabList>

        <TabPanel>
          <div className="location-containers">
            <Input
              key="AutocompleteId"
              id="AutocompleteId"
              placeholder="Search Unsplash"
              style={{ margin: "5%" }}
              value={value}
              onChange={(val) => {
                setValue(val.target.value);
              }}
              action={{
                icon: "search",
                onClick: () => {
                  getUnsplashImages();
                },
              }}
            />
          </div>

          <div>
            {img.map((item) => (
              <RenderItem key={Math.random() * 1000} item={item} />
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <div>
              <div
                onClick={openFileExp}
                style={
                  file.length > 0
                    ? {
                        backgroundImage: `url(${file})`,
                        backgroundColor: "transparent",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "40vh",
                      }
                    : {
                        display: "flex",
                        flexFlow: "column",
                        backgroundColor: "var(--light-grey)",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40vh",
                      }
                }
              >
                <FiCamera
                  size={80}
                  color="#a6a6a6"
                  style={file.length > 0 ? { display: "none" } : {}}
                />
                <p
                  style={
                    file.length > 0 ? { display: "none" } : { color: "#a6a6a6" }
                  }
                >
                  Touch to add an item image
                </p>
                <input
                  type="file"
                  id="ItemPhoto"
                  name="ItemPhoto"
                  ref={inputFile}
                  className=""
                  style={{ display: "none" }}
                  onChange={handleChange}
                  accept="image/*"
                />
              </div>
            </div>
            {file.length > 0 ? (
              <div className="button-cont">
                <button
                  className="uploadImage"
                  onClick={() => {
                    history.goBack();
                    dispatch(
                      loadNewImage({ author: "", url: file, downloadLink: "" })
                    );
                    imageToBase64(file) // Path to the image
                      .then((response: any) => {
                        dispatch(loadNewBase64(response));
                      })
                      .catch((error: any) => {
                        console.log(error); // Logs an error if there was one
                      });
                  }}
                >
                  Upload Picture
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </>
  );
};
