export type Base64 = "string";

interface base64Actions {
  LOAD_NEW_BASE64: string;
  DELETE_DATA: string;
}

const base64ActionType: base64Actions = {
  LOAD_NEW_BASE64: "LOAD_NEW_BASE64",
  DELETE_DATA: "DELETE_DATA",
};

export const loadNewBase64 = (data: Base64) => ({
  type: base64ActionType.LOAD_NEW_BASE64,
  payload: data,
});

export const deleteBase64 = () => ({
  type: base64ActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

const base64Reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case base64ActionType.LOAD_NEW_BASE64:
      return { ...state, data: action.payload };
    case base64ActionType.DELETE_DATA:
      return { ...state, data: null };
    default:
      return state;
  }
};

export default base64Reducer;
