import React, { useEffect, useState } from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Button, Checkbox, Form } from "semantic-ui-react";
import { InputExampleInput } from "../Inputs";
import { NavBarTopCustom } from "../Navbar";
import styled from "styled-components";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Auth } from "aws-amplify";
import { stringify } from "querystring";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  deleteCurrentSet,
  fetchCurrentSetData,
  loadCurrentSet,
} from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import { deleteLocation, loadNewLocation } from "../../redux/location";
import { shallowEqual } from "react-redux";
import { fetchSetSuccess } from "../../redux/sets";
import Cliploader from "react-spinners/ClipLoader";
import authorization from "../../authorization";
import Calendar from "react-calendar";

export const EditSetForm = (props: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentSet = useAppSelector(
    (state) => state.currentSet.data,
    shallowEqual
  );

  const currentLocation = useAppSelector((state) => state.location.data);

  const [setTitle, setSetTitle] = useState<string>("");
  const [setDesc, setSetDesc] = useState<string>("");
  const [cat, setCat] = useState([{ id: "", name: "" }]);
  const [act, setAct] = useState([{ id: "", name: "" }]);
  const [setActivityType, setSetActivityType] = useState("");
  const [setCategory, setSetCategory] = useState("");
  const [setPrivate, setSetPrivate] = useState("");
  const [userSub, setUserSub] = useState("");
  const sets = useAppSelector((state) => state.set.data);

  const [location, setLocation] = useState({
    name: "",
    formatted_address: "",
    type: "",
  });

  const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getSet?id=${props.match.params.id}`;

  const createSetUrl = `${process.env.REACT_APP_AWS_ENDPOINT1}updateSet`;
  const [isLoaded, setIsLoaded] = useState(true);
  const categories = useAppSelector((state) => state.category.data);
  const activities = useAppSelector((state) => state.activity.data);
  const user = useAppSelector((state) => state.user.data);
  const [startedDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [startDateTimeHours, setStartDateTimeHours] = useState(0);
  const [startDateTimeMinutes, setStartDateTimeMinutes] = useState(0);
  const [endDateTimeHours, setEndDateTimeHours] = useState(0);
  const [endDateTimeMinutes, setEndDateTimeMinutes] = useState(0);
  const [timedSet, setTimedSet] = useState(false);
  const [snafleAction, setSnafleAction] = useState(false);

  const activityTypes = [
    "Climb",
    "Collect",
    "Cook",
    "Drink",
    "Eat",
    "Find",
    "Fish",
    "Golf",
    "Listen",
    "Read",
    "Ride",
    "Run",
    "See",
    "Selfie",
    "Smell",
    "Visit",
    "Walk",
    "Watch",
    "Work Out",
  ];
  const history = useHistory();
  type Category = { id: string; name: string };
  const fetchSetData = async (id: string) => {
    return await fetch(url, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((json) => {
        setSetTitle(json.title);
        setSetDesc(json.description);
        setSetCategory(json.category.id);
        setSetActivityType(json.activityType.name);
        setTimedSet(json.timedSet);
        setStartDate(json?.startDate ? new Date(json?.startDate) : new Date());
        setEndDate(json?.endDate ? new Date(json?.endDate) : new Date());
        setStartDateTimeHours(new Date(json.startDate).getHours());
        setStartDateTimeMinutes(new Date(json.startDate).getMinutes());
        setEndDateTimeHours(new Date(json.endDate).getHours());
        setEndDateTimeMinutes(new Date(json.endDate).getMinutes());
        setSnafleAction(json?.snafleAction === "PIXEL_CANVAS");
      });
  };

  const getUserCred = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      console.log("user is signed in");
      //setUserAuthenticated(true);
      Auth.currentSession().then((res) => {
        let accessToken = res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        setUserSub(res.getIdToken().payload.sub);
        //console.log(accessToken.payload);
        //You can print them to see the full objects
        //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
        //console.log(`myJwt: ${jwt}`);
        //this.accessToken = JSON.stringify(accessToken);
        //this.setState({ token: `${JSON.stringify(accessToken)}` });
      });
      return true;
    } catch (err) {
      console.log("user is not signed in");
      return false;
    }
  };

  const getData = async () => {
    //dispatch(fetchCurrentSetData(url));
    fetchSetData(props.match.params.id);
  };

  useEffect(() => {
    setSetTitle(currentSet?.title);
    setSetDesc(currentSet?.description);
    setSetCategory(currentSet?.category?.id);
    setSetActivityType(currentSet?.activityType?.name);
    setLocation(currentLocation || currentSet?.location);
    setStartDate(currentSet?.startDate);
    setEndDate(currentSet?.endDate);
    setTimedSet(currentSet?.timedSet);
    setStartDate(currentSet?.startDate);
    setEndDate(currentSet?.endDate);
    setStartDateTimeHours(currentSet?.startDateTimeHours || 0);
    setStartDateTimeMinutes(currentSet?.startDateTimeMinutes || 0);
    setEndDateTimeHours(currentSet?.endDateTimeHours || 0);
    setEndDateTimeMinutes(currentSet?.endDateTimeMinutes || 0);
    setSnafleAction(
      currentSet?.snafleAction === "PIXEL_CANVAS" ||
        currentSet?.snafleAction === true
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
    getUserCred();
    const compareStrings = (a: string, b: string) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    };
    let categoriesSorted = categories;
    categoriesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });

    let activitiesSorted = activities;
    activitiesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });

    setCat(categoriesSorted);
    setAct(activitiesSorted);
  }, []);

  const editSet = async (event: any) => {
    event.preventDefault();
    console.log({
      id: props.match.params.id,
      title: setTitle,
      description: setDesc,
      location,
      activityType: setActivityType || undefined,
      categoryId: setCategory || undefined,
      timedSet,
      startDate: startedDate?.toISOString() || undefined,
      endDate: endDate?.toISOString() || undefined,
      snafleAction,
    });
    let data = {
      id: props.match.params.id,
      title: setTitle,
      description: setDesc,
      location,
      activityType: setActivityType || undefined,
      categoryId: setCategory || undefined,
      timedSet,
      startDate: startedDate?.toISOString() || undefined,
      endDate: endDate?.toISOString() || undefined,
      snafleAction: snafleAction === true ? "PIXEL_CANVAS" : "REGULAR",
    };
    console.log(data);
    await fetch(createSetUrl, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => console.log(data));

    dispatch(deleteLocation());
    dispatch(deleteCurrentSet());
    dispatch(deleteCurrentItem());

    setTimeout(() => {
      history.goBack();
    }, 1500);
  };

  const deleteSet = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}deleteSet`;

    const data = {
      id: userSub,
      setId: props.match.params.id,
      webapp: "true",
    };
    console.log(data);
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  const handleCheckbox = (event: any, data: any) => {
    setTimedSet(data.checked);
    dispatch(loadCurrentSet({ ...currentSet, timedSet: data.checked }));
  };

  const handleCalendar = (event: any) => {
    console.log({ start: event[0], end: event[1] });
    setStartDate(event[0]);
    setEndDate(event[1]);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: event[0],
        endDate: event[1],
      })
    );
  };

  const generateHours = () => {
    let details: { text: string; value: number; key: number }[] = [];
    for (let i = 0; i < 24; i++) {
      details.push({ text: `${i}`, value: i, key: i });
    }
    return details;
  };

  const generateMins = () => {
    let details: { text: string; value: number; key: number }[] = [];
    for (let i = 0; i < 60; i++) {
      details.push({ text: `${i}`, value: i, key: i });
    }
    return details;
  };

  const handleHoursStart = (event: any, data: any) => {
    setStartDate(
      new Date(startedDate.setHours(data.value, startDateTimeMinutes, 0))
    );
    setStartDateTimeHours(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: new Date(
          startedDate.setHours(data.value, startDateTimeMinutes, 0)
        ),
        startDateTimeHours: data.value,
      })
    );
  };
  const handleMinutesStart = (event: any, data: any) => {
    setStartDate(
      new Date(startedDate.setHours(startDateTimeHours, data.value, 0))
    );
    setStartDateTimeMinutes(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        startDate: new Date(
          startedDate.setHours(startDateTimeHours, data.value, 0)
        ),
        startDateTimeMinutes: data.value,
      })
    );
  };

  const handleHoursEnd = (event: any, data: any) => {
    setEndDate(new Date(endDate.setHours(data.value, endDateTimeMinutes, 0)));
    setEndDateTimeHours(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        endDate: new Date(endDate.setHours(data.value, endDateTimeMinutes, 0)),
        endDateTimeHours: data.value,
      })
    );
  };
  const handleMinutesEnd = (event: any, data: any) => {
    setEndDate(new Date(endDate.setHours(endDateTimeHours, data.value, 0)));
    setEndDateTimeMinutes(data.value);
    dispatch(
      loadCurrentSet({
        ...currentSet,
        endDate: new Date(endDate.setHours(endDateTimeHours, data.value, 0)),
        endDateTimeMinutes: data.value,
      })
    );
  };

  const handleSnafleAction = (event: any, data: any) => {
    setSnafleAction(data.checked);
    dispatch(loadCurrentSet({ ...currentSet, snafleAction: data.checked }));
  };

  return (
    <div>
      <NavBarTopCustom
        buttonLeft="Discard"
        title="Edit Snafle Set"
        buttonRight=""
      />

      <Form className="custom-form" onSubmit={editSet}>
        <Form.Field required>
          <label>Title</label>
          <Input
            required={true}
            placeholder="Title your Snafle Set"
            value={setTitle}
            onChange={(val) => {
              setSetTitle(val.target.value);
              dispatch(
                loadCurrentSet({ ...currentSet, title: val.target.value })
              );
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label>Description</label>
          <textarea
            required={true}
            placeholder="Describe what your Snafle Set Involves? Include rules, instructions or tips!"
            id="SetDesc"
            className=""
            name="SetDesc"
            value={setDesc}
            onChange={(val) => {
              setSetDesc(val.target.value);
              dispatch(
                loadCurrentSet({ ...currentSet, description: val.target.value })
              );
            }}
            style={{ resize: "none" }}
          ></textarea>
        </Form.Field>
        <p style={{ fontSize: "0.8em", color: "var(--grey-color)" }}>
          Add #tags to help users discover you set
        </p>

        <Form.Field className="custom-form-group" required>
          <label>Private Snafle</label>
          <Checkbox
            toggle
            type="checkbox"
            id="SetPrivate"
            name="SetPrivate"
            className=""
            onChange={() => {}}
          />
        </Form.Field>
        <p style={{ fontSize: "0.8em", color: "var(--grey-color)" }}>
          Make your set private to keep it only visible to you
        </p>
        <Form.Field required>
          <label>What activity type does it involve?</label>
          <select
            required={true}
            value={setActivityType}
            onChange={(val) => {
              setSetActivityType(val.target.value);
              dispatch(
                loadCurrentSet({
                  ...currentSet,
                  activityType: val.target.value,
                })
              );
            }}
          >
            <option selected={true} disabled={true}>
              Pick an activity type
            </option>
            {act.map((item, key) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </Form.Field>
        <Form.Field>
          <label>Add Location?</label>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              border: "1px solid var(--light-grey)",
              borderRadius: "5px",
              alignItems: "center",
              padding: "3% 5%",
              justifyContent: "space-between",
            }}
          >
            <div className="inner-location">
              <HiOutlineLocationMarker size={20} />
              {location?.name.length > 0 ? location.name : "Select Location"}
            </div>
            <Link to="/createSnafleSet/setLocation">Change</Link>
          </div>
        </Form.Field>
        <Form.Field required>
          <label>Set Category</label>
          <select
            required={true}
            value={setCategory}
            onChange={(val) => {
              setSetCategory(val.target.value);
              dispatch(
                loadCurrentSet({ ...currentSet, categoryId: val.target.value })
              );
            }}
          >
            <option selected={true} disabled={true}>
              Select Catagory
            </option>
            {cat.map((item: { id: string; name: string }) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Form.Field>
        <Form.Field required>
          <label>Timed Set</label>
          <Checkbox toggle checked={timedSet} onChange={handleCheckbox} />
          <div style={{ margin: "2% 0" }}>
            {timedSet ? (
              <div>
                <Calendar
                  selectRange={true}
                  onChange={handleCalendar}
                  value={[startedDate, endDate]}
                />
                <p>Start Time</p>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <p>Hours</p>
                    <Dropdown
                      placeholder="Hours"
                      selection
                      options={generateHours()}
                      onChange={handleHoursStart}
                      value={startDateTimeHours}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <p>Minutes</p>
                    <Dropdown
                      placeholder="Minutes"
                      selection
                      options={generateMins()}
                      onChange={handleMinutesStart}
                      value={startDateTimeMinutes}
                    />
                  </div>
                </div>
                <p>End Time</p>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <p>Hours</p>
                    <Dropdown
                      placeholder="Hours"
                      selection
                      options={generateHours()}
                      onChange={handleHoursEnd}
                      value={endDateTimeHours}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <p>Minutes</p>
                    <Dropdown
                      placeholder="Minutes"
                      selection
                      options={generateMins()}
                      onChange={handleMinutesEnd}
                      value={endDateTimeMinutes}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Form.Field>
        <Form.Field>
          <label>Pixel Set</label>
          <Checkbox
            toggle
            checked={snafleAction}
            onChange={handleSnafleAction}
          />
        </Form.Field>
        <Form.Field>
          <Button
            style={{
              width: "100%",
              backgroundColor: "var(--primary-color)",
              color: "white",
              borderRadius: 8,
            }}
            type="submit"
            onClick={() => setIsLoaded(false)}
          >
            Confirm Edit <span style={{ padding: "0 1%" }} />
            {!isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
          </Button>
        </Form.Field>
        <Button
          style={{
            width: "100%",
            backgroundColor: "#D90D00",
            color: "white",
            borderRadius: 8,
            margin: "10% 0",
          }}
          type="button"
          onClick={() => {
            deleteSet();
            let userSets = sets;
            let setIndex = userSets.findIndex(
              (v: any) => v.id === props.match.params.id
            );
            if (setIndex > -1) {
              userSets.splice(setIndex, 1);
            }
            dispatch(fetchSetSuccess(userSets));
            history.push("/");
          }}
        >
          Delete Set
        </Button>
      </Form>
    </div>
  );
};
