import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { last } from "lodash";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";

export const Maps = ({ data }: any) => {
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const containerStyle = {
    width: "100%",
    height: "30vh",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCnDt8uFLm2QRWPh2OzkJjAH4UQEL9sJZ4",
  });

  useEffect(() => {
    console.log(data);
    const fetchGeocode = async () => {
      if (data?.type === "PLACE") {
        let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data?.formatted_address}&sensor=true&key=AIzaSyCnDt8uFLm2QRWPh2OzkJjAH4UQEL9sJZ4`;

        console.log("go");

        setLoading(false);

        return await fetch(url, { method: "GET" })
          .then((data) => data.json())
          .then((res) => {
            data.lat = res.results[0].geometry.location.lat as number;
            data.lng = res.results[0].geometry.location.lng as number;
            console.log(data);
            setCenter({ lat: data.lat, lng: data.lng });
            setLoading(true);
          });
      } else if (data.type === "CITY") {
        setCenter({ lat: data.lat, lng: data.lng });
      }
    };
    fetchGeocode();
  }, []);

  const [map, setMap] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      {isLoaded && data.type !== "GLOBAL" && data.type !== undefined ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={() => {}}
          onUnmount={() => {}}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Marker position={center} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </>
  );
};
