import { Storage, Auth } from "aws-amplify";
import axios from "axios";
/*

@desc uploads the image to s3 without going through lambda

@params assetName: the filename on s3, bufferAsset: the base64 binary data, uploadedBy: user metadata

@returns the base64 buffer

*/

export type S3MetadataType = {
  usersub: string;
  type: "IMAGE";
  source: TaskType;
  sourceId: string;
  cognitoId?: string;
  setIdForItem?: string;
  author?: string; // unsplash author
  downloadLink?: string; //unsplash download link
};

Storage.configure({
  customPrefix: {
    public: "snaflerPublic/",
    protected: "snaflerProtected/",
    private: "snaflerPrivate/",
  },
});

export enum UploadLevel {
  PUBLIC = "public",
  PROTECTED = "protected",
}
export type LevelType = UploadLevel.PUBLIC | UploadLevel.PROTECTED;

export type TaskType =
  | "set"
  | "item"
  | "post"
  | "profile_cover"
  | "profile_image"
  | "profile_thumbnail"
  | "report_problem"
  | "feedback";

export const getBase64Buffer = async (data: {
  author: string;
  url: string;
  downloadLink: string;
}) => {
  return await axios
    .get(data.url, { responseType: "arraybuffer" })
    .then((res) => Buffer.from(res.data as string, "base64"));
};

export const getBufferFromFile = async (base64: string) => {
  return Buffer.from(base64, "base64");
};

export const uploadDirectToS3 = async (
  assetName: string,
  bufferAsset: Buffer,
  metadata: S3MetadataType,
  level: LevelType
): Promise<void> => {
  const credentials = await Auth.currentUserCredentials();
  const metadataCognito: S3MetadataType = {
    ...metadata,
    cognitoId: credentials.identityId,
  };

  //Upload to S3 bucket via amplify storage API

  let config: any = {
    level,
    cacheContent: "must-revalidate",
    contentType: "image/jpeg",
    metadata: metadataCognito,
  };
  if (level === UploadLevel.PUBLIC) {
    config = {
      ...config,
      acl: "public-read", //so the object in the s3 bucket is not encrypted }
    };
  }
  const result = await Storage.put(assetName, bufferAsset, config).catch(
    (err) => {
      console.log(err);
    }
  );

  console.log(result);
};
