import React, { useEffect, useRef, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link, useHistory } from "react-router-dom";
import { Button, Checkbox, Form } from "semantic-ui-react";
import { NavBarTopCustom } from "../Navbar";
import { FiCamera } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteLocation } from "../../redux/location";
import { deleteCurrentItem, loadCurrentItem } from "../../redux/currentItem";
import { deleteCurrentSet } from "../../redux/currentSet";
import { deleteImage } from "../../redux/image";
import { Data } from "../Images";
import { deleteBase64 } from "../../redux/base64";
import Cliploader from "react-spinners/ClipLoader";
import authorization from "../../authorization";
import { Auth } from "aws-amplify";
import {
  getBase64Buffer,
  getBufferFromFile,
  uploadDirectToS3,
  UploadLevel,
} from "../../uploadToS3";

// const activityTypes = [
//   "Climb",
//   "Collect",
//   "Cook",
//   "Drink",
//   "Eat",
//   "Find",
//   "Fish",
//   "Golf",
//   "Listen",
//   "Read",
//   "Ride",
//   "Run",
//   "See",
//   "Selfie",
//   "Smell",
//   "Visit",
//   "Walk",
//   "Watch",
//   "Work Out",
// ];

export const ItemForm = (props: any) => {
  const currentItem = useAppSelector((state) => state.currentItem.data);
  const [isLoaded, setIsLoaded] = useState(true);
  const [itemTitle, setItemTitle] = useState<string>(currentItem?.title || "");
  const [itemDesc, setItemDesc] = useState<string>(
    currentItem?.description || ""
  );

  const [itemImage, setItemImage] = useState<Data>({
    author: "",
    url: "",
    downloadLink: "",
  });

  const [geofence, setGeofence] = useState(currentItem?.geofence || false);

  const [base64, setBase64] = useState("");

  const [user, setUser] = useState("");

  Auth.currentSession().then(async (res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    setUser(res.getIdToken().payload.sub);
  });

  const setId = props.match.params.id;
  const setTitle = props.match.params.title;
  const snafleAction = useAppSelector(
    (state) => state.currentSet?.data?.snafleAction
  );
  const location = useAppSelector((state) => state.location.data);
  const image = useAppSelector((state) => state.image.data);
  const base64Data = useAppSelector((state) => state.base64.data);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const createSetItemUrl = `${process.env.REACT_APP_AWS_ENDPOINT1}createItem`;

  const createItem = async (event: any) => {
    Auth.currentSession().then(async (res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      setUser(res.getIdToken().payload.sub);
    });

    event.preventDefault();

    let data = {
      setId,
      title: itemTitle,
      description: itemDesc,
      location: location || {
        name: "Global",
        type: "GLOBAL",
        formatted_address: "GLOBAL",
      },
      hasImage: itemImage?.author?.length > 0 || base64 ? true : false,
      createdBy: user,
      geofence,
      snafleAction,
    };
    console.log(data);

    const item = await fetch(createSetItemUrl, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => data.json())
      .then((data) => {
        dispatch(deleteLocation());
        dispatch(deleteCurrentSet());
        dispatch(deleteCurrentItem());
        dispatch(deleteBase64());
        dispatch(deleteImage());
        return data;
      });

    if (itemImage?.author?.length > 0) {
      await uploadImage(item.itemId, image);
    } else if (base64Data) {
      await uploadBase64(item.itemId, base64Data);
    }
    setTimeout(() => {
      history.goBack();
    }, 1500);
  };

  const uploadBase64 = async (assetId: string, base64: string) => {
    const data = await getBufferFromFile(base64);
    console.log({ setId });
    await uploadDirectToS3(
      `${assetId}.jpg`,
      data,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        setIdForItem: setId,
        sourceId: assetId,
      },
      UploadLevel.PUBLIC
    );
  };

  const uploadImage = async (
    assetId: string,
    data: {
      author: string;
      url: string;
      downloadLink: string;
    }
  ) => {
    const res = await getBase64Buffer(data);

    await uploadDirectToS3(
      `${assetId}.jpg`,
      res,
      {
        usersub: user,
        type: "IMAGE",
        source: "item",
        sourceId: assetId,
        setIdForItem: setId,
        author: data.author,
        downloadLink: data.downloadLink,
      },
      UploadLevel.PUBLIC
    );
  };

  const handleCheckbox = (event: any, data: any) => {
    // console.log(data);
    setGeofence(data.checked);
    dispatch(loadCurrentItem({ ...currentItem, geofence: data.checked }));
  };

  useEffect(() => {
    setItemImage(image || { author: "", url: "", downloadLink: "" });
    setBase64(base64 || base64Data);
    Auth.currentAuthenticatedUser();
    console.log("user is signed in");
    Auth.currentSession().then(async (res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      setUser(res.getIdToken().payload.sub);
    });
  }, [dispatch]);

  return (
    <div>
      <NavBarTopCustom buttonLeft="Discard" title="Add Item" buttonRight="" />
      <Form style={{ paddingBottom: "20%" }} onSubmit={createItem}>
        <Form.Field>
          <div
            onClick={() => {
              history.push("/createSnafleItem/setImage");
            }}
            style={
              image?.url.length > 0
                ? {
                    backgroundImage: `url(${itemImage.url})`,
                    backgroundColor: "transparent",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "40vh",
                  }
                : {}
            }
            className="placeholder-image"
          >
            <FiCamera
              size={80}
              color="#a6a6a6"
              style={image?.url.length > 0 ? { display: "none" } : {}}
            />
            <p
              style={
                image?.url.length > 0
                  ? { display: "none" }
                  : { color: "#a6a6a6" }
              }
            >
              Touch to add an item image
            </p>
          </div>
        </Form.Field>
        <div className="custom-item-form">
          <Form.Field required>
            <label>Title</label>
            <input
              required={true}
              type="text"
              id="ItemTitle"
              name="ItemTitle"
              placeholder="What is the title of your item?"
              value={itemTitle}
              onChange={(val) => {
                setItemTitle(val.target.value);
                dispatch(
                  loadCurrentItem({
                    ...currentItem,
                    title: val.target.value,
                  })
                );
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label>Description</label>
            <textarea
              required={true}
              id="ItemDesc"
              className=""
              name="ItemDesc"
              placeholder="What does you item involve? Include rules, instructions or tips!"
              value={itemDesc}
              onChange={(val) => {
                setItemDesc(val.target.value);
                dispatch(
                  loadCurrentItem({
                    ...currentItem,
                    description: val.target.value,
                  })
                );
              }}
              style={{ resize: "none" }}
            ></textarea>
          </Form.Field>
          <Form.Field>
            <label>Location</label>
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                border: "1px solid var(--light-grey)",
                borderRadius: "5px",
                alignItems: "center",
                padding: "3% 5%",
                justifyContent: "space-between",
              }}
            >
              <div className="inner-location">
                <HiOutlineLocationMarker size={20} />
                {location?.name.length > 0 ? location.name : "Select Location"}
              </div>
              <Link to="/createSnafleItem/setLocation">Change</Link>
            </div>
          </Form.Field>
          <Form.Field>
            <label>Geofencing</label>
            <Checkbox toggle checked={geofence} onChange={handleCheckbox} />
            <p style={{ fontSize: "" }}>
              Only allow players to check in if they are within 250 metres of
              the location
            </p>
          </Form.Field>

          <Form.Field>
            <Button
              style={{
                width: "100%",
                backgroundColor: "var(--primary-color)",
                color: "white",
                borderRadius: 8,
              }}
              type="submit"
              onClick={() => setIsLoaded(false)}
            >
              Create Item <span style={{ padding: "0 1%" }} />
              {!isLoaded ? <Cliploader size={12} color={"white"} /> : <></>}
            </Button>
          </Form.Field>
        </div>
      </Form>
    </div>
  );
};
