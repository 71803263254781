import React from "react";
import "./missionControl.css";
import logo from "../assets/snafler-header-logo.png";
import { FaUsers } from "react-icons/fa";
import { BiTrendingUp, BiTrendingDown, BiMessageDetail } from "react-icons/bi";
import { BsCollectionFill } from "react-icons/bs";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { NavBar } from "../components";
import authorization from "../authorization";

const date = new Date("Oct 30, 2021 10:30:00").getTime();

class MissionControl extends React.Component {
  state = {
    date: "Loading",
    users: 0,
    sets: 0,
    posts: 0,
    changeUser: 0,
    setsChange: 0,
    postChange: 0,
    mostRecent: "Wait for actions to happen",
  };

  ws = new WebSocket(
    "wss://0dq9myca3c.execute-api.eu-west-1.amazonaws.com/prod"
  );

  getStats = async () => {
    const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getDatabaseData`;
    await fetch(url, { method: "GET", headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        this.setState({
          users: data.userNum,
          sets: data.setNum,
          posts: data.postNum,
        });
      })
      .catch((err) => console.log(err));
  };

  getCountDown = () => {
    const currentTime = new Date().getTime();
    const difference = date - currentTime;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days}d:${hours}h:${minutes}m:${seconds}s`;
  };

  componentDidMount() {
    this.getStats();
    setInterval(() => {
      this.setState({ date: this.getCountDown() });
    }, 1000);

    /**
     * sockets
     */

    this.ws.onopen = () => {
      console.log("connected");
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "POST") {
        this.setState({
          posts: ++this.state.posts,
          postChange: ++this.state.postChange,
          mostRecent: `${message.mostRecent.username} just snafled ${message.mostRecent.itemTitle} from the set, ${message.mostRecent.setTitle}`,
        });
      } else if (message.type === "USER") {
        this.setState({
          users: ++this.state.users,
          changeUser: ++this.state.changeUser,
          mostRecent: `${message.username} just joined Snafler!`,
        });
      } else if (message.type === "SET") {
        this.setState({
          sets: ++this.state.sets,
          setChange: ++this.state.setsChange,
          mostRecent: `${message.mostRecent.username} just created the Snafle Set: ${message.mostRecent.setTitle}`,
        });
      } else {
        console.log({ message });
      }
    };
    this.ws.onerror = (event) => {
      console.log(event);
    };
  }

  componentWillUnmount() {
    this.ws.close();
  }

  DataContainer = ({ data }: any): React.ReactElement => {
    return (
      <div className={"navigationItem"} style={{ backgroundColor: data.color }}>
        <div className={"first-row"}>
          <data.Icon size={50} style={{ padding: 0, margin: 0 }} />
          <h3 className={"mission-title-text"}>{data.title}</h3>
          <h1 className={"data-text"}>{data.primaryData}</h1>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className={"mission-body-container"}>
          {/**
           * main body
           */}
          {/* <h1>Development Complete ETA : {this.state.date}</h1> */}
          <div className={"mission-data"}>
            {/* <h2>Snafler Stats</h2>
            <h5>Most Recent: {this.state.mostRecent}</h5> */}
            {/* <div className={"mission-data-containers-container"}> */}
            {/**
             * data boxes - sets, items, snafles, posts,
             */}
            <div className={"mission-data-containers-container"}>
              <this.DataContainer
                data={{
                  Icon: FaUsers,
                  color: "white",
                  title: "Users",
                  change: this.state.changeUser,
                  primaryData: this.state.users,
                }}
              />

              <this.DataContainer
                data={{
                  Icon: BiMessageDetail,
                  color: "white",
                  title: "Posts",
                  change: this.state.postChange,
                  primaryData: this.state.posts,
                }}
              />
              <this.DataContainer
                data={{
                  Icon: BsCollectionFill,
                  color: "white",
                  title: "Sets",
                  change: this.state.setsChange,
                  primaryData: this.state.sets,
                }}
              />
            </div>
            <div>
              <Link to="/stats">More Statistics</Link>
            </div>
            <div className={"mission-data-containers-container"}>
              <Link className={"navigationItem"} to="/home">
                <h3>Sets and Items</h3>
              </Link>
              <Link className={"navigationItem"} to="/discoverData">
                <h3>Discover Page</h3>
              </Link>
              <Link className={"navigationItem"} to="/addActivities">
                <h3>Activities</h3>
              </Link>
              <Link className={"navigationItem"} to="/addCategories">
                <h3>Categories</h3>
              </Link>
              <Link className={"navigationItem"} to="/Moderation">
                <h3>Content Moderation</h3>
              </Link>
              <Link className={"navigationItem"} to="/UserFeedback">
                <h3>User Feedback</h3>
              </Link>
              <Link className={"navigationItem"} to="/ContentCreator">
                <h3>Content Creator Requests</h3>
              </Link>
            </div>
            {/* <div>
              <p>Stats from QA Databases</p>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default MissionControl;
