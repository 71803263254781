import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Tab, Table } from "semantic-ui-react";
import { NavBar } from "..";
import authorization from "../../authorization";

const moderationDateSort = [
  { key: "NEW", value: "NEWEST", text: "NEWEST-OLDEST" },
  { key: "OLDEST", value: "OLDEST", text: "OLDEST-NEWEST" },
  { key: "NORM", value: "NORM", text: "NORM" },
];

const moderationStatusOptions = [
  { key: "ALL", value: "ALL", text: "ALL" },
  {
    key: "REJECTED",
    value: "REJECTED",
    text: "REJECTED",
  },

  {
    key: "ACCEPTED",
    value: "ACCEPTED",
    text: "ACCEPTED",
  },

  {
    key: "PENDING",
    value: "PENDING",
    text: "PENDING",
  },
];

const ContentCreator = () => {
  const TableRowVerification = (props: {
    username: string;
    timestamp: number;
    status: string;
    reason: string;
    fullName: string;
    documentType: string;
    alsoKnownAs: string;
    audience: string;
    requestCategory: string;
    requestId: string;
    type: string;
    userSub: string;
  }) => {
    // console.log({ userSub: props.username });
    return (
      <Table.Row key={props.requestId}>
        {/* <Table.Cell>{props.username}</Table.Cell> */}
        <Table.Cell>{new Date(props.timestamp).toISOString()}</Table.Cell>
        <Table.Cell>{props.reason}</Table.Cell>
        <Table.Cell>{props.type}</Table.Cell>
        <Table.Cell>{props.status}</Table.Cell>
        <Table.Cell>
          <Link to={`/ContentCreator/VER_${props.userSub}_${props.requestId}`}>
            View Request
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  const TableRowsVerification = (props: { data: any }) => {
    return props.data.map((x: any) => <TableRowVerification {...x} />);
  };

  const VerificationTableContent = () => {
    const [data, setData] = useState<any>([]);
    const [tempData, setTempData] = useState<any>([]);
    const getVerificationRequests = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=CONTENT_CREATOR`;
      await fetch(url, {
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.items);
          setData(res.items);
          setTempData(res.items);
        });
    };

    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [modTime, setModTime] = useState("NORM");
    useEffect(() => {
      getVerificationRequests();
    }, []);

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(tempData);
        setModerationStatus("ALL");
        return;
      }
      setModerationStatus(item.value);
      if (modTime !== "NORM") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(tempData.filter((x: any) => x.status === item.value));
      }
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) => a[attributeName] - b[attributeName])
          );
        } else {
          setData(
            data.sort((a: any, b: any) => b[attributeName] - a[attributeName])
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            tempData.sort(
              (a: any, b: any) => a[attributeName] - b[attributeName]
            )
          );
        } else {
          setData(
            tempData.sort(
              (a: any, b: any) => b[attributeName] - a[attributeName]
            )
          );
        }
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
              <Table.HeaderCell>
                Request Timestamp
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationDateSort}
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Requester's Message</Table.HeaderCell>
              <Table.HeaderCell>Request Type</Table.HeaderCell>
              <Table.HeaderCell>
                Request Status
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationStatusOptions}
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View{" "}
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(tempData);
                      setModTime("NORM");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsVerification data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const VerificationTableAmbassador = () => {
    const [data, setData] = useState<any>([]);
    const [tempData, setTempData] = useState<any>([]);
    const getVerificationRequests = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=AMBASSADOR`;
      await fetch(url, {
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.items);
          setData(res.items);
          setTempData(res.items);
        });
    };

    useEffect(() => {
      getVerificationRequests();
    }, []);

    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [modTime, setModTime] = useState("NORM");

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(tempData);
        setModerationStatus("ALL");
        return;
      }
      setModerationStatus(item.value);
      if (modTime !== "NORM") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(tempData.filter((x: any) => x.status === item.value));
      }
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) => a[attributeName] - b[attributeName])
          );
        } else {
          setData(
            data.sort((a: any, b: any) => b[attributeName] - a[attributeName])
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            tempData.sort(
              (a: any, b: any) => a[attributeName] - b[attributeName]
            )
          );
        } else {
          setData(
            tempData.sort(
              (a: any, b: any) => b[attributeName] - a[attributeName]
            )
          );
        }
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
              <Table.HeaderCell>
                Request Timestamp
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationDateSort}
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Requester's Message</Table.HeaderCell>
              <Table.HeaderCell>Request Type</Table.HeaderCell>
              <Table.HeaderCell>
                Request Status
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationStatusOptions}
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View{" "}
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(tempData);
                      setModTime("NORM");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsVerification data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const VerificationTableBrand = () => {
    const [data, setData] = useState<any>([]);
    const [tempData, setTempData] = useState<any>([]);
    const getVerificationRequests = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=BRAND`;
      await fetch(url, {
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.items);
          setData(res.items);
          setTempData(res.items);
        });
    };

    useEffect(() => {
      getVerificationRequests();
    }, []);
    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [modTime, setModTime] = useState("NORM");

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(tempData);
        setModerationStatus("ALL");
        return;
      }
      setModerationStatus(item.value);
      if (modTime !== "NORM") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(tempData.filter((x: any) => x.status === item.value));
      }
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) => a[attributeName] - b[attributeName])
          );
        } else {
          setData(
            data.sort((a: any, b: any) => b[attributeName] - a[attributeName])
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            tempData.sort(
              (a: any, b: any) => a[attributeName] - b[attributeName]
            )
          );
        } else {
          setData(
            tempData.sort(
              (a: any, b: any) => b[attributeName] - a[attributeName]
            )
          );
        }
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
              <Table.HeaderCell>
                Request Timestamp
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationDateSort}
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Requester's Message</Table.HeaderCell>
              <Table.HeaderCell>Request Type</Table.HeaderCell>
              <Table.HeaderCell>
                Request Status
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationStatusOptions}
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(tempData);
                      setModTime("NORM");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsVerification data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const VerificationTableInfluencer = () => {
    const [data, setData] = useState<any>([]);
    const [tempData, setTempData] = useState<any>([]);
    const getVerificationRequests = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT2}getCCPRs?type=INFLUENCER`;
      await fetch(url, {
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.items);
          setData(res.items);
          setTempData(res.items);
        });
    };

    useEffect(() => {
      getVerificationRequests();
    }, []);
    const [moderationStatus, setModerationStatus] = useState("ALL");
    const [modTime, setModTime] = useState("NORM");

    const handleModerationTimeSort = (event: any, item: any) => {
      orderTime("timestamp", item.value);
      setModTime(item.value);
    };

    const handleStatusChange = (event: any, item: any) => {
      if (item.value === "ALL") {
        setData(tempData);
        setModerationStatus("ALL");
        return;
      }
      setModerationStatus(item.value);
      if (modTime !== "NORM") {
        setData(data.filter((x: any) => x.status === item.value));
      } else {
        setData(tempData.filter((x: any) => x.status === item.value));
      }
    };

    const orderTime = (
      attributeName: string,
      type: "NEWEST" | "OLDEST" | "NORM"
    ) => {
      if (modTime !== "NORM" || moderationStatus !== "ALL") {
        if (type === "OLDEST") {
          setData(
            data.sort((a: any, b: any) => a[attributeName] - b[attributeName])
          );
        } else {
          setData(
            data.sort((a: any, b: any) => b[attributeName] - a[attributeName])
          );
        }
      } else {
        if (type === "OLDEST") {
          setData(
            tempData.sort(
              (a: any, b: any) => a[attributeName] - b[attributeName]
            )
          );
        } else {
          setData(
            tempData.sort(
              (a: any, b: any) => b[attributeName] - a[attributeName]
            )
          );
        }
      }
    };

    return (
      <Tab.Pane>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              {/* <Table.HeaderCell>Usersub</Table.HeaderCell> */}
              {/* <Table.HeaderCell>Username</Table.HeaderCell> */}
              <Table.HeaderCell>
                Request Timestamp
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationDateSort}
                    value={modTime}
                    onChange={handleModerationTimeSort}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>Requester's Message</Table.HeaderCell>
              <Table.HeaderCell>Request Type</Table.HeaderCell>
              <Table.HeaderCell>
                Request Status
                <div>
                  <Dropdown
                    selection
                    fluid
                    options={moderationStatusOptions}
                    value={moderationStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                View
                <div>
                  <Button
                    primary
                    onClick={() => {
                      setData(tempData);
                      setModTime("NORM");
                      setModerationStatus("ALL");
                    }}
                  >
                    Reset Filters
                  </Button>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data ? <TableRowsVerification data={data} /> : <></>}
          </Table.Body>
        </Table>
      </Tab.Pane>
    );
  };

  const panes = [
    {
      menuItem: "Content Creator Requests",
      render: () => <VerificationTableContent />,
    },
    {
      menuItem: "Brands Requests",
      render: () => <VerificationTableBrand />,
    },
    {
      menuItem: "Influencer Requests",
      render: () => <VerificationTableInfluencer />,
    },
    {
      menuItem: "Ambassador Requests",
      render: () => <VerificationTableAmbassador />,
    },
  ];
  return (
    <>
      <NavBar />
      <div className="mod-main-conatiner">
        <Tab panes={panes} renderActiveOnly={true} />
      </div>
    </>
  );
};

export default ContentCreator;
