import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Input } from "semantic-ui-react";
import authorization from "../../authorization";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadCurrentCatAct } from "../../redux/currentCatAct";

type Category = { id: string; name: string };

export const AddCatComp = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.category.data);
  const [cat, setCat] = useState([{ id: "", name: "", imageUrl: "" }]);
  const [value, setValue] = useState("");
  const [responseText, setResponseText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [newData, setNewData] = useState(false);
  const compareStrings = (a: string, b: string) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    let categoriesSorted = categories;
    categoriesSorted.sort((a: Category, b: Category) => {
      return compareStrings(a.name, b.name);
    });
    setCat(categoriesSorted);
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getCategories`;
      await fetch(url, { headers: { ...(await authorization()) } })
        .then((data) => data.json())
        .then((data) => {
          setCat(
            data.sort((a: Category, b: Category) => {
              return compareStrings(a.name, b.name);
            })
          );
        });
    };
    getCategories();
  }, [newData]);

  const addCategory = async () => {
    const body = {
      name: value,
    };
    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}createCategory`;
    const data = await fetch(url, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(async (data) => {
      if (data.status === 201) {
        setResponseText("Category inserted into the database");
        setErrorText("");
        setCat([{ id: "", name: value, imageUrl: "" }, ...cat]);
        setNewData(!newData);
      } else {
        setResponseText("");
        setErrorText("Category already exists");
      }
    });
  };

  const Image = ({ alt, item }: any) => {
    const [error, setError] = useState(false);

    const onError = (err: string) => {
      setError(true);
      console.log(err);
    };

    return error ? (
      <img
        src={`${process.env.REACT_APP_AWS_BUCKET1}/snafler-logo.png`}
        alt={alt}
        className="imageOverlay"
        onError={(e) => onError(e.currentTarget.innerText)}
        onClick={() => {
          dispatch(loadCurrentCatAct(item));
          history.push("/addCategories/addImage");
        }}
      />
    ) : (
      <img
        src={`${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${
          item.id
        }.jpg?${Math.random()}`}
        alt={alt}
        className="imageOverlay"
        onError={(e) => onError(e.currentTarget.innerText)}
        onClick={() => {
          dispatch(loadCurrentCatAct(item));
          history.push("/addCategories/addImage");
        }}
      />
    );
  };

  const deleteCategory = async (id: string) => {
    const body = {
      id,
    };

    const url = `${process.env.REACT_APP_AWS_ENDPOINT1}/deleteCategory`;
    await fetch(url, {
      method: "DELETE",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((data) => {
      setNewData(!newData);
    });
  };

  const RenderComponent = (props: {
    item: { id: string; name: string; imageUrl: string };
  }) => (
    <div className="current-categories" key={props.item.id}>
      <Image item={props.item} alt="Category Image" />

      <div className="itemText">{props.item.name}</div>
    </div>
  );

  return (
    <div>
      <div className="nav-bar-top">
        {/**
         * Header
         *
         */}
        <h3>Add Categories</h3>
      </div>
      <div className="location-containers">
        {/**
         * Add categories
         */}
        <Input
          key="AutocompleteId"
          id="AutocompleteId"
          placeholder="Add Categories"
          style={{ margin: "5%" }}
          value={value}
          onChange={(val) => {
            setValue(val.target.value);
          }}
          action={{
            icon: "plus",
            onClick: () => {
              addCategory();
            },
          }}
        />
        <p className="smallText" style={{ color: "green" }}>
          {responseText}
        </p>
        <p className="smallText" style={{ color: "red" }}>
          {errorText}
        </p>
      </div>
      <div className="container">
        <h4 style={{ textAlign: "center" }}>Current Categories</h4>
        {/* <p>
          Images uploaded here will not be uploaded to the correct location -
          Contact Euan Gordon to get any images merged over.
        </p>
        <p>This will be updated ASAP</p> */}
        <div className="current-cat-container">
          {/**
           * Current List
           */}

          {cat.map((index) => (
            <RenderComponent item={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
