import React, { useState, useEffect } from "react";

import { FiChevronLeft, FiImage, FiSettings } from "react-icons/fi";
import { BiShareAlt } from "react-icons/bi";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../custom-btn/cstm-btn.component";
import { Menu } from "../Menu";
import { SetItemData } from "../../redux/sets";
import { useAppDispatch } from "../../hooks";
import { fetchCurrentSetData } from "../../redux/currentSet";
import { deleteCurrentItem } from "../../redux/currentItem";
import ScaleLoader from "react-spinners/ScaleLoader";
//@ts-ignore
import Shimmer from "react-shimmer-effect";
import authorization from "../../authorization";
import { Dropdown } from "semantic-ui-react";
export interface SnafleSet {
  id: string;
  title: string;
  description: string;
  username?: string;
  subscriberCount: number;
  numItems: number;
  status: string;
}

export const NavContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 8% 5%;
  position: fixed;
  width: 100%;
  top: 1%;
  @media (min-width: 750px) {
    padding: 2% 5%;
  }
`;

export const TopContainer = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  color: white;
  width: 100%;
`;

export const EmptyItemsCont = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SetComp = (props: any) => {
  const subTypes = [
    { key: "FREE", value: "FREE", text: "Free" },
    { key: "PRO", value: "PRO", text: "Pro" },
    { key: "CHARITY", value: "CHARITY", text: "Charity" },
  ];

  const statuses = [
    { key: "PUBLISHED", value: "PUBLISHED", text: "Published" },
    { key: "DRAFT", value: "DRAFT", text: "Draft" },
  ];

  const SFX = () => {
    let values = [];
    for (let i = 1; i <= 10; i++) {
      values.push({ key: i * 100, value: i * 100, text: i * 100 });
    }
    return values;
  };
  const FIAT = () => {
    let values = [];
    for (let i = 1; i <= 10; i++) {
      values.push({ key: i * 5, value: i * 5, text: i * 5 });
    }
    return values;
  };

  const [setData, setSetData] = useState<SnafleSet>();
  const [setAuthor, setSetAuthor] = useState("");
  const [setDesc, setSetDesc] = useState("");
  const [setSubCount, setSetSubCount] = useState(0);
  const [numItems, setNumItems] = useState(0);
  const [setTitle, setSetTitle] = useState("");
  const [setItemData, setSetItemData] = useState<SetItemData[]>([]);
  const [setId, setSetId] = useState("");
  const [status, setStatus] = useState("");
  const [subType, setSubType] = useState("FREE");
  const [subCost, setSubCost] = useState(0);
  const [subSFX, setSubSFX] = useState(0);
  const url = `${process.env.REACT_APP_AWS_ENDPOINT1}getSet?id=${props.match.params.id}`;
  const getItems = `${process.env.REACT_APP_AWS_ENDPOINT1}getItemsBySet?id=${props.match.params.id}&webapp=true`;
  const publish = `${process.env.REACT_APP_AWS_ENDPOINT1}updateSet`;
  const [ownerId, setOwnerId] = useState("");
  const fetchSetData = async () => {
    return await fetch(url, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setSetAuthor(data.username! as string);
        setSetTitle((data.title! as string).replaceAll("/", "%2F"));
        setNumItems(data.numItems);
        setSetDesc(data.description! as string);
        setSetSubCount(data.subscriberCount);
        setSetId(data.id);
        setStatus(data.status);
        setSubType(data.subscriptionType);
        setSubCost(data.fiatValue);
        setSubSFX(data.SFXValue);
        setOwnerId(data.ownedBy);
      });
  };

  const owners = [
    { key: ownerId, value: ownerId, text: setAuthor },
    {
      key: process.env.REACT_APP_SNAFLER_OFFICIAL,
      value: process.env.REACT_APP_SNAFLER_OFFICIAL,
      text: "Snafler",
    },
  ];

  const fetchItemsBySet = async () => {
    return await fetch(getItems, { headers: { ...(await authorization()) } })
      .then((data) => data.json())
      .then((data) => {
        console.log({ data });
        setSetItemData(
          data.items.map((item: SetItemData) => ({
            id: item.id,
            title: item.title,
            description: item.description,
          }))
        );
      });
  };

  const updateSet = async (data: {
    SFXValue?: number;
    fiatValue?: number;
    status?: string;
    ownedBy?: string;
    subscriptionType?: string;
  }) => {
    await fetch(publish, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await authorization()),
      },
      body: JSON.stringify({
        id: props.match.params.id,
        ...data,
      }),
    })
      .then((data) => data.json())
      .then((data) => console.log(data));
  };

  const changeSFX = (event: any, data: any) => {
    setSubSFX(data.value);
    setSubType("PRO");
    updateSet({ SFXValue: data.value, fiatValue: 0, subscriptionType: "PRO" });
  };
  const changeFiat = (event: any, data: any) => {
    setSubCost(data.value);
    setSubType("CHARITY");
    updateSet({
      fiatValue: data.value,
      SFXValue: 0,
      subscriptionType: "CHARITY",
    });
  };
  const changeStatus = (event: any, data: any) => {
    setStatus(data.value);
    updateSet({ status: data.value });
  };
  const changeOwner = (event: any, data: any) => {
    setOwnerId(data.value);
    updateSet({ ownedBy: data.value });
  };
  const changeSubType = (event: any, data: any) => {
    setSubType(data.value);
    setSubSFX(0);
    setSubCost(0);
    updateSet({ subscriptionType: data.value, SFXValue: 0, fiatValue: 0 });
  };

  const publishToSnafler = async () => {
    return await fetch(publish, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await authorization()),
      },
      body: JSON.stringify({
        id: props.match.params.id,
        ownedBy: process.env.REACT_APP_SNAFLER_OFFICIAL,
        status: "PUBLISHED",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          history.goBack();
        }, 1500);
      });
  };
  const publishSet = async () => {
    return await fetch(publish, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(await authorization()),
      },
      body: JSON.stringify({
        id: props.match.params.id,
        status: "PUBLISHED",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      });
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSetData();
    dispatch(fetchCurrentSetData(url));
    dispatch(deleteCurrentItem());
    fetchItemsBySet();
  }, [dispatch]);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <div>
      {isLoading ? (
        <div className="loadingScreen">
          <ScaleLoader />
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, 0.4)), url(${process.env.REACT_APP_AWS_BUCKET1}/snaflerPublic/${props.match.params.id}.jpg`,
            }}
            className="top-image-container"
          >
            <NavContainer>
              <FiChevronLeft
                color="white"
                size={24}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.goBack();
                }}
              />
              <div className="icons-right">
                <FiImage
                  style={{ cursor: "pointer" }}
                  color="white"
                  size={24}
                  onClick={() => {
                    history.push(`/editSetImage/${props.match.params.id}`);
                  }}
                />
                <FiSettings
                  style={{ cursor: "pointer" }}
                  color="white"
                  size={24}
                  onClick={() => {
                    history.push(`/editSet/${props.match.params.id}`);
                  }}
                />
              </div>
            </NavContainer>

            <TopContainer>
              <h2>{setTitle}</h2>
              <p>Created By: {setAuthor}</p>
              <p>
                {numItems} Items | {setSubCount} Subscribers
              </p>
            </TopContainer>
            <div style={{ textAlign: "center" }}>
              <CustomButton
                buttonTitle="Add Item"
                connection={`/set/${props.match.params.id}/createSnafleItem/${setTitle}`}
              />
            </div>
          </div>
          <div className="item-set-cont">
            <div className="set-desc-cont">
              <h3>Set Info</h3>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>OWNER</p>
                <div style={{ margin: "0 1%" }}>
                  <Dropdown
                    selection
                    options={owners}
                    value={ownerId}
                    onChange={changeOwner}
                    placeholder="Select a status"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>STATUS</p>
                <div style={{ margin: "0 1%" }}>
                  <Dropdown
                    selection
                    options={statuses}
                    value={status}
                    onChange={changeStatus}
                    placeholder="Select a status"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  margin: "2% 0",
                }}
              >
                <p>SUBSCRIPTION TYPE</p>
                <div style={{ margin: "0 1%" }}>
                  <Dropdown
                    selection
                    options={subTypes}
                    value={subType}
                    onChange={changeSubType}
                    placeholder="Select a Subscription Type"
                  />
                </div>
              </div>
              {/* <br />
                <br />
                PRICE FIAT: £{subCost || "0"} */}
              {subType === "CHARITY" ? (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "2% 0",
                  }}
                >
                  <p>PRICE FIAT GBP</p>
                  <div style={{ margin: "0 1%" }}>
                    <Dropdown
                      selection
                      options={FIAT()}
                      value={subCost}
                      onChange={changeFiat}
                      placeholder="Select a FIAT Value"
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    margin: "2% 0",
                  }}
                >
                  <p>PRICE SFX</p>
                  <div style={{ margin: "0 1%" }}>
                    <Dropdown
                      selection
                      options={SFX()}
                      value={subSFX}
                      onChange={changeSFX}
                      placeholder="Select an SFX Value"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="set-desc-cont" style={{ width: "80%" }}>
              <h3>Set Description</h3>
              <p>{setDesc}</p>
            </div>
            {setItemData.length > 0 ? (
              <Menu
                data={setItemData}
                dataPassThrough="item"
                setId={props.match.params.id}
              />
            ) : (
              <div style={{ height: "40vh" }}>
                <EmptyItemsCont>
                  <Link
                    style={{ color: "var(--primary-color)" }}
                    to={`/set/${props.match.params.id}/createSnafleItem/${setTitle}`}
                  >
                    <h2>Add Some Items</h2>
                  </Link>
                </EmptyItemsCont>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
