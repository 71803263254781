/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-1",
  aws_cognito_identity_pool_id:
    "eu-west-1:181866ac-6580-4c76-9176-b62075fe20be",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_WWZkBppIM",
  aws_user_pools_web_client_id: "74eu3tp6ltj931896ilffn9b6m",
  oauth: {
    domain: "snafler-production.auth.eu-west-1.amazoncognito.com",
    scope: [
      "aws.cognito.signin.user.admin",
      "email",
      "openid",
      "phone",
      "profile",
    ],
    redirectSignIn: "snafler://",
    redirectSignOut: "snafler://",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_user_files_s3_bucket: "snafler-datastore-production",
  aws_user_files_s3_bucket_region: "eu-west-1",
  aws_mobile_analytics_app_id: "07f44990616743c7aa25c65c74bb7e5b",
  aws_mobile_analytics_app_region: "eu-west-1",
};

export default awsmobile;
