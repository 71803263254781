interface CurrentCatAct {
  id: string;
  name: string;
  assetName: string;
  iconType: string;
}

interface currentCatActActions {
  LOAD_CURRENT_CATACT: string;
  DELETE_DATA: string;
}

const currentCatActActionTypes: currentCatActActions = {
  LOAD_CURRENT_CATACT: "LOAD_CURRENT_CATACT",
  DELETE_DATA: "DELETE_DATA",
};

export const loadCurrentCatAct = (data: CurrentCatAct) => ({
  type: currentCatActActionTypes.LOAD_CURRENT_CATACT,
  payload: data,
});

export const deleteCurrentCatAct = () => ({
  type: currentCatActActionTypes.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

const currentCatActReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case currentCatActActionTypes.LOAD_CURRENT_CATACT:
      return { ...state, data: action.payload };
    case currentCatActActionTypes.DELETE_DATA:
      return { ...state, date: null };
    default:
      return state;
  }
};

export default currentCatActReducer;
