import React, { createContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import Navigator from "./navigatior";
import "dotenv";
import "./App.css";
import { NavBar } from "./components";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { fetchUserSubAsync, loadNewUser } from "./redux/user";
import { useAppDispatch, useAppSelector } from "./hooks";
import { fetchCategoriesAsync } from "./redux/categories";
import { fetchSetAsync } from "./redux/sets";
import { fetchActivitiesAsync } from "./redux/activity";
import authorization from "./authorization";

export const AuthContext = createContext(null);

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const [authorised, setAuthorised] = useState<boolean>(false);

  const checkAuthorized = async () => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT2}webappCheck`, {
      headers: { ...(await authorization()) },
    })
      .then((data) => {
        if (data.status === 200) setAuthorised(true);
      })
      .catch((err) => setAuthorised(false));
  };

  useEffect(() => {
    checkAuthorized();
    dispatch(fetchUserSubAsync());
    dispatch(fetchCategoriesAsync());
    dispatch(fetchActivitiesAsync());

    const getSets = async (): Promise<boolean> => {
      let accessToken;
      console.log("Checking AUTH RUNNING");
      try {
        await Auth.currentAuthenticatedUser();

        //setUserAuthenticated(true);
        Auth.currentSession().then((res) => {
          accessToken = res.getAccessToken();
          let jwt = accessToken.getJwtToken();
          console.log(res.getIdToken());

          // dispatch(fetchSetAsync(res.getAccessToken().payload.sub));
        });
        return true;
      } catch (err) {
        return false;
      }
    };
    getSets();
  }, [dispatch]);

  return authorised ? (
    <Navigator />
  ) : (
    <div>
      <h1>You are not authorized</h1>
      <div>
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            outline: "none",
            fontSize: "1.2em",
            cursor: "pointer",
          }}
          onClick={() => {
            Auth.signOut();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default withAuthenticator(App);
