import authorization from "../../authorization";
import { useAppSelector } from "../../hooks";
import { fetchUserSubAsync } from "../user";
const url = (user: string) =>
  `${process.env.REACT_APP_AWS_ENDPOINT1}getUserObject?userSub=${user}&sets=true`;

interface setActions {
  FETCH_SET_START: string;
  FETCH_SET_SUCCESS: string;
  FETCH_SET_FAIL: string;
}

export type Status =
  | "DRAFT"
  | "PUBLISHED"
  | "REVIEW"
  | "PENDING_REVIEW"
  | "REJECTED"
  | "DELETED";

export interface SetData {
  id: string;
  title: string;
  description: string;
  location: {
    name: string;
    formatted_address?: string;
    country_code?: string;
    lng?: number;
    lat?: number;
    photo_reference?: string;
    type?: string;
  };
  createdBy: string;
  category: string;
  tags: string[];
  SFXValue: number;
  verified: boolean;
  assetUrl: string[];
  numItems: number[];
}

export interface Location {
  name: string;
  formatted_address?: string;
  country_code?: string;
  lng?: number;
  lat?: number;
  photo_reference?: string;
  type?: string;
}

export interface SetItemData {
  id: string;
  title: string;
  description: string;
  createdBy: string;
  createdDate: string;
  modifiedDate: string;
  modifiedBy: string;
  location: Location;
  activityType: string;
  SFXValue: number;
  tags: string[];
  status: Status;
  publishingDate: string;
  publishingTime: string;
  reviewedDate: string;
  reviewedTime: string;
  sourceLanguage: string;
  assetUrl: string[];
  itemSets: { setId: string; setTitle: string }[];
}

const setActionTypes: setActions = {
  FETCH_SET_START: "FETCH_SET_START",
  FETCH_SET_SUCCESS: "FETCH_SET_SUCCESS",
  FETCH_SET_FAIL: "FETCH_SET_FAIL",
};

export const fetchSetStart = () => ({
  type: setActionTypes.FETCH_SET_START,
});

export const fetchSetSuccess = (data: SetData[]) => ({
  type: setActionTypes.FETCH_SET_SUCCESS,
  payload: data,
});

export const fetchSetFail = (errorMessage: string) => ({
  type: setActionTypes.FETCH_SET_FAIL,
  payload: errorMessage,
});

const INITIAL_STATE = {
  data: [],
  isFetching: false,
  errorMessage: undefined,
};

export const fetchSetAsync = (user: string) => {
  // return async (dispatch: any) => {
  //   dispatch(fetchSetStart());
  //   await fetch(url("269e2220-2529-4fbd-a531-7ce5107f6330"), {
  //     method: "GET",
  //     headers: {
  //       ...(await authorization()),
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((jsonData) =>
  //       dispatch(
  //         fetchSetSuccess(
  //           jsonData.sets.map((data: SetData) => ({
  //             id: data.id,
  //             title: data.title,
  //             description: data.description,
  //             numItems: data.numItems,
  //           }))
  //         )
  //       )
  //     )
  //     .catch((err) => dispatch(fetchSetFail(err)));
  // };
};

const setReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case setActionTypes.FETCH_SET_START:
      return { ...state, isFetching: true };
    case setActionTypes.FETCH_SET_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };
    case setActionTypes.FETCH_SET_FAIL:
      return { ...state, isFetching: false, errorMessage: action.payload };

    default:
      return state;
  }
};

export default setReducer;
