import { Auth } from "aws-amplify";

interface userActions {
  LOAD_NEW_USER: string;
  DELETE_DATA: string;
}

type user = string;

const userActionType: userActions = {
  LOAD_NEW_USER: "LOAD_NEW_USER",
  DELETE_DATA: "DELETE_DATA",
};

export const loadNewUser = (data: user) => ({
  type: userActionType.LOAD_NEW_USER,
  payload: data,
});

export const deleteUser = () => ({
  type: userActionType.DELETE_DATA,
});

const INITIAL_STATE = {
  data: null,
};

export const fetchUserSubAsync = () => {
  return async (dispatch: any) => {
    await Auth.currentAuthenticatedUser();
    console.log("user is signed in");
    //setUserAuthenticated(true);
    Auth.currentSession().then(async (res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      dispatch(loadNewUser(res.getIdToken().payload.sub));
      //console.log(accessToken.payload);
      //You can print them to see the full objects
      //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      //console.log(`myJwt: ${jwt}`);
      //this.accessToken = JSON.stringify(accessToken);
      //this.setState({ token: `${JSON.stringify(accessToken)}` });
    });
  };
};

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case userActionType.LOAD_NEW_USER:
      return { ...state, data: action.payload };
    case userActionType.DELETE_DATA:
      return { ...state, data: "" };
    default:
      return state;
  }
};

export default userReducer;
