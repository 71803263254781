import { stat } from "fs";
import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { SetForm, ItemForm } from "../components";
import { CustomButton } from "../components/custom-btn/cstm-btn.component";
import { Menu } from "../components/Menu/menu.component";
import { NavBar, BottomNavbar } from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchSetAsync, fetchSetStart } from "../redux/sets";
import { Auth } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { Floating, MenuContainerHome } from "./home.style";
import { fetchCategoriesAsync } from "../redux/categories";
import { fetchUserSubAsync, loadNewUser } from "../redux/user";
import { deleteCurrentSet } from "../redux/currentSet";
import { deleteCurrentItem } from "../redux/currentItem";
import { deleteLocation } from "../redux/location";
import { deleteImage } from "../redux/image";
import { deleteBase64 } from "../redux/base64";
import { Link } from "react-router-dom";
import authorization from "../authorization";
import { Select } from "semantic-ui-react";

const Home = () => {
  // const data = useAppSelector((state) => state.set.data);
  const [data, setData] = useState<any>([]);
  const [userDrafts, setUserDrafts] = useState<any>([]);
  const [lastVal, setLastVal] = useState();
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(true);
  const options = [
    { key: "YOU", value: true, text: "Snafler Account Sets" },
    { key: "SNAFLER", value: false, text: "Your Sets" },
  ];
  // console.log(data);
  const dispatch = useAppDispatch();
  let last = "";
  const user = process.env.REACT_APP_SNAFLER_OFFICIAL;

  const getSets = async () => {
    setLoading(true);
    const sub = await Auth.currentUserInfo().then((res) => res.attributes.sub);

    await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT1}getUserObject?id=${
        currentUser ? user : sub
      }&sets=true&webapp=true`,
      {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((jsonData) => {
        // last = jsonData.lastVal.id;
        // console.log(jsonData);
        setData(
          jsonData.sets.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.description,
            numItems: data.numItems,
          }))
        );
      });
    await getDraftSets();
  };

  const getDraftSets = async () => {
    if (currentUser === false) {
      await fetch(`${process.env.REACT_APP_AWS_ENDPOINT1}getDraftSets`, {
        method: "GET",
        headers: {
          ...(await authorization()),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsonData) => {
          // last = jsonData.lastVal.id;
          console.log(jsonData);

          setUserDrafts(
            jsonData.items.map((data: any) => ({
              id: data.id,
              title: data.title,
              description: data.description,
              numItems: data.numItems,
            }))
          );
        });
    }
  };

  // const moreSets = async (last: any) => {
  //   return await fetch(
  //     `${process.env.REACT_APP_AWS_ENDPOINT1}getUserObject?id=${user}&sets=true&lastVal=${last}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         ...(await authorization()),
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((jsonData) => {
  //       console.log({ lastVal: jsonData.lastVal });

  //       const newData = jsonData.sets.map((data: any) => ({
  //         id: data.id,
  //         title: data.title,
  //         description: data.description,
  //         numItems: data.numItems,
  //       }));
  //       setData([...data, ...newData]);
  //       // return jsonData.lastVal.id;
  //     });
  // };

  // const handleScroll = async () => {
  //   let userScollHeight = window.innerHeight + window.scrollY;
  //   let windowBottomHeight = document.documentElement.offsetHeight;
  //   if (userScollHeight > windowBottomHeight) {
  //     last = await moreSets(last);
  //   }
  // };

  const handleChange = (e: any, { value }: any) => setCurrentUser(value);

  useEffect(() => {
    getSets();

    // window.addEventListener("scroll", handleScroll); // atta
    dispatch(deleteLocation());
    dispatch(deleteCurrentSet());
    dispatch(deleteCurrentItem());
    dispatch(deleteImage());
    dispatch(deleteBase64());
  }, [currentUser]);

  return (
    <div>
      <NavBar location="Sets" />

      {data.length > 0 || userDrafts.length > 0 ? (
        <>
          <div
            style={{ flexFlow: "row wrap", justifyContent: "space-between" }}
          >
            <div
              className="title-cont"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                zIndex: 0,
              }}
            >
              <Select
                placeholder="Select your country"
                options={options}
                onChange={handleChange}
                value={currentUser}
              />
              <Link to="/createSnafleSet" style={{ margin: "2% 0 0 0" }}>
                Create Set
              </Link>
              {/* <p>
                Warning: this is using the <strong>dev</strong> database, any
                sets/items created here need to migrated over - contact Euan
                Gordon.
              </p>
              <p>This will be updated ASAP</p> */}
            </div>
            {/* <div></div> */}
          </div>
          <MenuContainerHome>
            <Menu
              setId=""
              data={currentUser ? data : [...data, ...userDrafts]}
              dataPassThrough="set"
            />
          </MenuContainerHome>
        </>
      ) : (
        <div className="noset">
          <Link to="/createSnafleSet" style={{ color: "var(--primary-color)" }}>
            <h2>Create your first set!</h2>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Home;
