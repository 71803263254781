import React, { useEffect, useState } from "react";
import { ButtonInvisible, NavbarContainer } from "./navbar.styles";
import { Auth } from "aws-amplify";
import logo from "../../assets/snafler-header-logo.png";
import authorization from "../../authorization";
import { Checkbox } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

export const NavBar = (props?: { location?: string }) => {
  const [appState, setAppState] = useState(false);
  const history = useHistory();
  const changeValue = (event: any, data: any) => {
    setAppState(data.checked);
    setMaintenanceMode(data.checked);
  };
  const getMaintenanceMode = async () => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT3}getAppMode`, {
      method: "GET",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setAppState(data?.maintenanceMode);
      });
  };

  useEffect(() => {
    getMaintenanceMode();
  }, []);

  const setMaintenanceMode = async (data: boolean) => {
    await fetch(`${process.env.REACT_APP_AWS_ENDPOINT3}setAppMode`, {
      method: "POST",
      headers: {
        ...(await authorization()),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        maintenanceMode: data,
      }),
    }).then((res) => console.log(res.status));
  };
  return (
    <NavbarContainer>
      <img
        src={logo}
        className="logo"
        onClick={() => {
          history.push("/");
        }}
      />

      <div className="nav-web">
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            outline: "none",
            fontSize: "1.2em",
            cursor: "pointer",
          }}
          onClick={() => {
            Auth.signOut();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }}
        >
          Logout
        </button>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "80%",
            width: "100%",
          }}
        >
          Maintenance Mode
          <Checkbox toggle onChange={changeValue} checked={appState} />
        </div>
      </div>
      <ButtonInvisible
        style={{ cursor: "pointer", color: "var(--primary-color)" }}
        onClick={() => {
          Auth.signOut();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }}
      >
        Logout
      </ButtonInvisible>
    </NavbarContainer>
  );
};
