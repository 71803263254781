import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-flow: column;
  margin: 5% 0;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 8px var(--grey-color);
  justifycontent: center;
  alignitems: center;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 750px) {
    flex-flow: row wrap;
    margin: 3%;
    width: 25%;
  }
`;

export const MenuImage = styled.div`
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10%;
  border-radius: 10px;
  height: 20vh;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const MenuDescription = styled.div`
  margin: 3% 5%;
  height: 11%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (min-width: 750px) {
    height: 13%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const MenuDescText = styled.p`
  margin: 1% 0;
`;
