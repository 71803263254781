import styled from "styled-components";

export const Button = styled.button`
  padding: 2% 10%;
  text-align:center;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  border-radius 20px;
  color: white;
  @media (min-width:750px){
    padding: 1% 2%;
  }

  &:hover{
    cursor:pointer;
  }
`;
